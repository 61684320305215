import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../../service";
import { Config } from "../../../utils/commonFuctions/Token";
import { utcTimetoLocalTimeWithzone } from '../../common/TransForm/Localdateformater';

import { setJackpots, setJackpotsDetails, } from "../../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import { useState } from "react";
import UpdatePopup from "../../Leagues/UpdatePopup";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";

export const Jackpots = () => {
    const navigate = useNavigate();
    const [jackPotData, setJackPotData] = useState([]);
    const [notification, setnotification] = useState(false)
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");

    const JackpotColumn = () => [
        {
            key: "id",
            label: "Jackpot ID",
            align: "left",
            fontSize: "15px",

            valueRenderer: (data) => {
                return (
                    <Button
                        component={Typography}
                        style={{ color: "#3C50E0", textDecoration: "underline" }}
                        onClick={() => navigate(`jackpots/details/${data.id}`, { state: { jackpotsdetails: data } })}
                    >
                        {data["id"]}
                    </Button>
                );
            },

        },
        {
            key: "pot",
            label: "Round ID",
            align: "left",
            fontSize: "15px",
            valueRenderer: (data) => {
                return <Typography> {data['pot'] || '—'}</Typography>;
            },
        },
        {
            key: "league_name",
            label: "League Name",
            align: "left",
            fontSize: "15px",
            valueRenderer: (data) => {
                return <Typography> {data['league_name']}</Typography>;
            },
        },
        {
            key: "deadline",
            label: "Deadline",
            align: "left",
            fontSize: "15px",
            valueRenderer: (data) => {
                let date = utcTimetoLocalTimeWithzone(data["deadline"]);
                const [datePart,timePart] = date.split(', ');
                return <Typography> {datePart}  ,  {timePart}</Typography>;
            },
        },
        {
            key: "status",
            label: "Status",
            align: "left",
            fontSize: "15px",
            valueRenderer: (data) => {
                return <Typography> {data['status']}</Typography>;
            },
        },
    ];




    const getJackpots = useCallback(async () => {
        setLoading(true)
        try {
            const response = await Axios.get(`${base_url}/api/pot/jackpot`, Config);
            if (response?.status == 200) {
                setJackPotData(response?.data)
                setLoading(false)
            }
        }
        catch (error) {
            RefreshToken(error, setErrorMessage, setnotification)
        }
    }, []);

    const gotoAddUser = () => {
        navigate("add");
    };

    useEffect(() => {
        getJackpots();
    }, []);

    return (
        <>
            <Box>
                <TransTable
                    showSearch={false}
                    checkbox={false}
                    columns={JackpotColumn()}
                    rows={jackPotData}
                    addBtnAction={gotoAddUser}
                    isLoading={loading}
                />
                <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

            </Box>
        </>
    );
}