



import React, { useState , useEffect } from 'react';
import {
  Box,
  Select,
  InputLabel,
  MenuItem,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Axios from "axios";
import { base_url } from '../../backendurl/url'
import { Config } from "../../../utils/commonFuctions/Token";

export const MenuSelect = ({
  label,
  placeholder,
  value,
  onChange,
  options,
  name,
  errors,
  validation,
  isDisabled,
  width,
  mode,
}) => {
  const [checkBoxData, setCheckBoxData] = useState(
    // dashboard: { read: false, write: false },
    // leagueseason: { read: false, write: false },
    // pots: { read: false, write: false },
    // user: { read: false, write: false },
    // payout: { read: false, write: false },
    // paymentsreceipts: { read: false, write: false },
    // potconfig: { read: false, write: false },
    // configuredleagues: { read: false, write: false },
    // teams: { read: false, write: false },
    // leaguematchweek: { read: false, write: false },
    // tickets: { read: false, write: false },
    // activepredictionshistory: { read: false, write: false },
    // matches: { read: false, write: false },
    // potplayers: { read: false, write: false },
    // standings: { read: false, write: false } ||
    value || {}
  );

  useEffect(()=>{
    setCheckBoxData(value)
  },[value])

  const [selectedSections, setSelectedSections] = useState([]);
  const [isopenSubMenu, issetOpenSubMenu] = useState(false);

  const handleChange = (section, permission) => (event) => {
    const { checked } = event.target;
    const updatedCheckBoxData = {
      ...checkBoxData,
      [section]: {
        ...checkBoxData[section],
        [permission]: checked,
        read:
          permission === 'read' ||
            (permission === 'write' || checked) ? checked : checked,
      },
    };

    setCheckBoxData(updatedCheckBoxData);

    const isSelected = Object.values(updatedCheckBoxData[section]).some((perm) => perm);
    let updatedSelectedSections = [...selectedSections];
    if (isSelected && !updatedSelectedSections.includes(section)) {
      updatedSelectedSections.push(section);
    } else if (!isSelected) {
      updatedSelectedSections = updatedSelectedSections.filter((s) => s !== section);
    }
    setSelectedSections(updatedSelectedSections);

    onChange('menu_permissions', updatedCheckBoxData);
  };
  
  return (
    <div>
      <InputLabel
        htmlFor={name}
        component="div"
        sx={{ fontWeight: 600, fontSize: 16, color: '#000', error: errors?.status }}
      >
        {label}
        {validation?.required && (
          <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>
            *
          </Typography>
        )}
      </InputLabel>

      <Select
        disabled={mode === 'view'}
        name={name}
        multiple
        displayEmpty
        value={selectedSections}
        renderValue={(selected) =>
          selected.length === 0 ? <em>{placeholder}</em> : selected.join(', ').toUpperCase()
        }
        sx={{
          width: '90%',
          margin: '1% 0 0 0',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
          },
        }}
      >
        {options?.map((data, index) => (
          <Box key={data.value}>
            {/* Main item */}
            <Stack direction="row" spacing={2} sx={{ padding: '4px 5%' }}>
              <MenuItem sx={{ minWidth: '170px' }}>{data.label}</MenuItem>
              {
                data.children ? '' :
                  <>
                    <FormControlLabel
                      label="Read"
                      control={
                        <Checkbox
                          checked={checkBoxData[data.value]?.read || false}
                          onClick={(event) => event.stopPropagation()}
                          onChange={handleChange(data.value, 'read')}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Write"
                      control={
                        <Checkbox
                          checked={checkBoxData[data.value]?.write || false}
                          onClick={(event) => event.stopPropagation()}
                          onChange={handleChange(data.value, 'write')}
                        />
                      }
                    />
                  </>
              }
              {data.children && (
                <IconButton
                  onClick={() => issetOpenSubMenu(!isopenSubMenu)}
                  sx={{ marginLeft: 'auto' }}
                >
                  {isopenSubMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </Stack>

            {isopenSubMenu && (
              <Box sx={{ marginLeft: '43px', padding: '8px 0' }}>
                {data?.children?.map((items, subIndex) => (
                  <Stack direction="row" spacing={2} key={subIndex} sx={{ padding: '4px 0' }}>
                    <MenuItem sx={{ minWidth: '170px' }}>{items.label}</MenuItem>
                    <FormControlLabel
                      label="Read"
                      control={
                        <Checkbox
                          checked={checkBoxData[items.value]?.read || false}
                          onClick={(event) => event.stopPropagation()}
                          onChange={handleChange(items.value, 'read')}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Write"
                      control={
                        <Checkbox
                          checked={checkBoxData[items.value]?.write || false}
                          onClick={(event) => event.stopPropagation()}
                          onChange={handleChange(items.value, 'write')}
                        />
                      }
                    />
                  </Stack>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Select>
    </div>
  );
};

