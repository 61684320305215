import { InputAdornment } from "@mui/material";
import lockicon from '../../../../images/lockIcon.png'
export default function configFormInputs(values) {
    return [
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
        },
        children: [         
          {
            shape: "input",
            type: "currencyinput",
            name:"value_of_token",
            title: "value of token",
            label: "Define a value of 01 token ",
            placeholder: "Enter the value",
            currencyDefaultValue: "AMD",
            isDisabled: values.tokendisable,
            inputAdornments:{
              end: values.tokendisable ?  <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment> : '',
            },
            mode: "edit",
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%',       
            validation: {
              required: true,
              requiredMessage: "value of token can't be empty",
            },     
          },          
          {
            shape: "input",
            type: "number",
            name: "adminfee",
            // handleChange: "",
            title: "adminfee",
            label: "Admin fee",
            className: "",
            placeholder: `Enter the value`,
            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "Admin Fee can't be empty",
            },
            // sx:{backgroundColor:'#0000001A', borderRadius:'12px', border:'none'},
            inputAdornments:{
              end: <InputAdornment position="end"> % </InputAdornment>,
            }
          }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
          mt: 3,
        },
        children: [
          {
            shape: "input",
            type: "currencyinput",
            name:"min_pot_value",
            title: "mix pot value",
            label: "Minimum Round value",
            priceDefaultValue: "100",
            placeholder: "Enter your value",
            currencyDefaultValue: "AMD",
            isDisabled: false,
            selectDisable: true,
            mode: "edit",
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%',   
            validation: {
              required: true,
              requiredMessage: "Minimum Round value can't be empty",
            },           
          },
          {
            shape: "input",
            type: "currencyinput",
            name:"max_pot_value",
            title: "max pot value",
            label: "Maximum Round value",
            priceDefaultValue: "100",
            placeholder: "Enter your value",
            currencyDefaultValue: "AMD",
            isDisabled: false,
            selectDisable: true,
            mode: "edit",
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%', 
            validation: {
              required: true,
              requiredMessage: "Maximum Round value can't be empty",
            },             
          }
          
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
          mt: 3,
        },
        children: [         
          {
            shape: "input",
            type: "number",
            name: "prediction_deadline",
            // handleChange: "",
            title: "Deadline for picks ",
            label: "Deadline for picks ",
            className: "",
            placeholder: `Enter the hours`,
            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "Enter the hours can't be empty",
            },
            // '(Hours before match of match week)'/
           
          },          
          {
            shape: "input",
            type: "number",
            name: "min_picks_for_splitting",
            // handleChange: "",
            title: "Minimum players to split the pot ",
            label: "Minimum players to split the Round",
            className: "",
            placeholder: `Enter number of players`,
            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "Minimum players can't be empty",
            },
            
          }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
          mt: 1,
        },
        children: [         
          {
            type: "textview",
            content:'(Hours before match of match week)',
            sx:{color:'#5F6672'}
          },          
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
          mt: 3,
        },
        children: [         
          {
            shape: "input",
            type: "number",
            name: "tokens_per_pick",
            // handleChange: "",
            title: "No. of tokens per pick",
            label: "No. of tokens per pick",
            className: "",
            placeholder: `Enter the value`,
            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "No. of tokens per pick can't be empty",
            },           
          },          
          {
            shape: "input",
            type: "number",
            name: "picks_per_player",
            // handleChange: "",
            title: "No. of picks per player",
            label: "No. of picks per player",
            className: "",
            placeholder: `Enter the value`,
            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "No. of picks per player can't be empty",
            },
            
          }
        ],
      },
    

      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: { display: "flex", justifyContents: "flex-start", mt: 3 },
        children: [
          {
            shape: "button",
            type: "action",
            title: values.mode === "view" ? "Edit" : "Update",
            submitButton : values.isupdate,
            name: "Add",
            sx : {
              px:4,
            },
            validation: {
              hiddenRules: [
                {
                  mode: "view",
                },
              ],
            },
          },
       
       
      ],
      },
      // {
  
      //     shape:"select",
      //     type: "select",
      //     label: "Role",
      //     name: "role",
      //     className:"border",
      //     validation: {
      //         required:true
      //     },
      //     options: [
      //         {
      //             label: "Admin",
      //             value: "admin"
      //         },
      //         {
      //             label: "User",
      //             value: "user"
      //         }
      //     ]
      // },
      // {
      //     type: "number",
      //     shape: "input",
      //     label: "Phone Number",
      //     name: "mobile",
      //     className: "border",
      //     title:"mobile",
      //     validation: {
      //         required: true,
      //         minLength: 10,
      //         maxLength:10
      //     }
      // },
    ];
  }
  