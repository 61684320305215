import { Box, IconButton, InputLabel } from "@mui/material";
import Iconify from "../Iconify";

export default function TransIconButton({ icon, onDeleteClick, formData }) {
  const deleteAction = () => {
    onDeleteClick && onDeleteClick(formData);
  };
  return (
    <Box>
      <InputLabel sx={{ height: 21 }}></InputLabel>
      <IconButton onClick={deleteAction}>
        <Iconify icon={icon} sx={{ fontSize: 25 }} color="secondary.main" />
      </IconButton>
    </Box>
  );
}
