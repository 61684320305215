import React, { useEffect, useState } from 'react';
import EditableAllDataGrid from '../common/TransForm/pnpdataGridAlledit';
import { base_url } from '../backendurl/url';
import Axios from 'axios';
import { Config } from '../../utils/commonFuctions/Token';
import { RefreshToken } from '../../utils/commonFuctions/RefreshToken';
import { Notification } from '../common/Notification';

function TeamsStanding({ league, season }) {
    const [jsonData, setJsonData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)
    const headers = [
        { name: 'rank', lable:'Rank', type: 'text' },
        { name: 'team_name', lable:'Team Name', type: 'text', },
        { name: 'points', lable:'Point', type: 'text' },
    ];
    const handleUpdateData =async (updatedData) => {
        try{
            const response = await Axios.put(`${base_url}/api/pot/standingsupdate/${league}`,updatedData, Config)
            if (response?.status == 200) {
                getStandings()
            }
        }
        catch(error){
            RefreshToken(error,setErrorMessage,setnotification)  
        }
       
    };
    const editableColumns = [0, 2];

    const getStandings = async () => {
        try{
            const response = await Axios.get(`${base_url}/api/pot/standings/${league}`, Config)
            if (response?.status == 200) {
                setJsonData(response?.data?.data)
            }
        }
       catch(error){
        RefreshToken(error,setErrorMessage,setnotification)  
       }
    }

    useEffect(() => {
        getStandings()
    }, [])

    return (
        <div>
            <EditableAllDataGrid headers={headers} jsonData={jsonData} onUpdateData={handleUpdateData} showAdd={false} editableColumns={editableColumns} />
      <Notification  message = {errorMessage} setopen={setnotification} type ={'error'} show={notification}  />

        </div>)
}

export default TeamsStanding


