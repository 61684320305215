import { Box, Typography } from "@mui/material";

export default function AuthWrapper({ title, subtitle, children }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
      <Typography fontSize={25} color="primary.main" fontWeight={600} sx={{mt:4}}>
        {title}
      </Typography>
      <Typography fontSize={15} color="#5B5B5B" fontWeight={500} mt={1} sx={{width :'301px', textAlign: "center",fontWeight: 500}}>
        {subtitle}
      </Typography>
      {children}
    </Box>
  );
}
