import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, Typography, Divider, Stack, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { Dateformater } from '../common/TransForm/dateFormater';
import { utcTimetoLocalTimeWithzone } from '../common/TransForm/Localdateformater';

export default function UpdatePopup({ title, subTitle, save, cancel, openpopup, setPopup, onClick, data, type }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 665,
  
    height: type=='update_results' ? 400 : 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  const resultData = {
    // Status: data?.result == 'None' ?'FT' : data?.result,
    Status:data?.status,
    hometeam_goal: data?.hometeam_goal == 'None' ? '' : data?.hometeam_goal,
    awayteam_goal: data?.awayteam_goal == 'None' ? '' : data?.awayteam_goal,
    matchResult: data?.result == 'None' ? '' : data?.result,
  }

 
useEffect(()=>{
  setFormData(type!='update_results'? {  start_date:data.start_date,
    start_time:data.start_time,
    Status:data.status
  }
  : {...resultData})
},[type])


  const [formData, setFormData] = useState(
    type!='update_results'?{start_date:data.start_date,
      start_time:data.start_time,
      Status:data.status
    }
: {...resultData})
    
  const [formErrors, setFormErrors] = useState({});
  const validateForm = () => {
    let errors = {};
    let isValid = true;

if(type=='update_results'){    if (formData.Status == '' || formData.Status == undefined || formData.Status === 'Select') {
      errors.Status = 'Please select a match status';
      isValid = false;
    }

    if (formData.matchResult == '' || formData.matchResult == undefined || formData.matchResult === 'Select') {
      errors.matchResult = 'Please select a match result';
      isValid = false;
    }

    if (formData.hometeam_goal == '' || formData.hometeam_goal == undefined) {
      errors.hometeam_goal = 'Home team goal cannot be empty';
      isValid = false;
    }

    if (formData.awayteam_goal == '' || formData.awayteam_goal == undefined) {
      errors.awayteam_goal = 'Away team goal cannot be empty';
      isValid = false;
    }}else{
      if (formData.start_date == '') {
        errors.matchResult = 'Please select a match date';
        isValid = false;
      }
      if (formData.start_time == '') {
        errors.matchResult = 'Please select a match time';
        isValid = false;
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSave = () => {
    if (validateForm()) {
      onClick(formData);
      // setPopup(false);
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
const MarchStatus = [
  {
    name : 'TBD',
    label: 'Time To Be Defined'
  },
  {
    name : 'NS',
    label: 'Not started'
  },
  {
    name : '1H',
    label: 'First Half,Kick Off'
  },
  {
    name : 'HT',
    label: 'Halftime'
  },
  {
    name : '2H',
    label: 'Second Half,2nd Half Started'
  },
  {
    name : 'ET',
    label: 'Extra Time'
  },
  {
    name : 'BT',
    label: 'Break Time'
  },
  {
    name : 'P',
    label: 'Penality In Progress'
  },
  {
    name : 'SUSP',
    label: 'Match Suspended'
  },
  {
    name : 'INT',
    label: 'Match Interrupted'
  },
  {
    name : 'FT',
    label: 'Finished in Regular Time'
  },
  {
    name : 'AET',
    label: 'Finished After Extra Time'
  },
  {
    name : 'PEN',
    label: 'Finished After The Penalty Shootout'
  },
  {
    name : 'PST',
    label: 'Match Postponed'
  },
  {
    name : 'CANC',
    label: 'Cancelled'
  },
  {
    name : 'ABD',
    label: 'Match Abandoned'
  },
  {
    name : 'AWD',
    label: 'Technical Loss'
  },
  {
    name : 'WO',
    label: 'Walk Over'
  },
  {
    name : 'LIVE',
    label: 'In Progress'
  }
]


const Status = [
  
  {
    name : 'PST',
    label: 'Match Postponed'
  },
  {
    name : 'CANC',
    label: 'Cancelled'
  },
  {
    name : 'ABD',
    label: 'Match Abandoned'
  },
  {
    name : 'AWD',
    label: 'Technical Loss'
  },
  {
    name : 'WO',
    label: 'Walk Over'
  },

]
  return (
    <Modal
      open={openpopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

        <Typography variant="h6" component="h2" sx={{ px: 6, mt: 2 }}>
          {title}
        </Typography>
        <Typography sx={{ mt: 2, px: 6,display : "flex" }}>
          {data?.match_week}
          <Divider orientation="vertical" style={{ height: '25px',margin : "auto 20px"}} />
          {data?.hometeam} V {data?.awayteam}
          <Divider orientation="vertical" style={{ height: '25px',margin : "auto 20px"}} />
          {Dateformater(data?.start)}
        </Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />

        <Box sx={{ mt: 6, px: 6 }}>
         {type=='update_results' ? <>
         <Stack direction="row" spacing={2} alignItems="center"  >
            <InputLabel sx={{ width: '110px', flexWrap: 'wrap' }}>Match Status
            <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>
            </InputLabel>
            <Stack direction="column" >
              <Select
                labelId="match-status-label"
                name="Status"
                value={formData.Status}
                onChange={handleChange}
                // error={!!formErrors.matchStatus} 
                // helperText={formErrors.matchStatus ? formErrors.matchStatus : ''}
                sx={{
                  width: '130px', height: '30px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none !important',
                  },
                  '& .MuiSelect-select': {
                    height: '10px !important',
                    minHeight: '10px !important'
                  }
                }}
              >

                {MarchStatus.map(item=>
                  <MenuItem value={item.name}>{item.label}</MenuItem>
                )}
                
            
                
              </Select>
              {formErrors.matchResult && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {formErrors.matchResult}
                </Typography>
              )}
            </Stack>

            <InputLabel sx={{ width: '100px', flexWrap: 'wrap' }}>{data?.hometeam} Goals
            <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>
            </InputLabel>
            <TextField
              name="hometeam_goal"
            
              value={formData.hometeam_goal}
              onChange={handleChange}
              error={!!formErrors.hometeam_goal}
              helperText={formErrors.hometeam_goal}
              sx={{ width: '130px', height: '40px' }}
            />
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center" my={5}>
            <InputLabel sx={{ width: '110px', flexWrap: 'wrap' }}>{data?.awayteam} Goals
            <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>

            </InputLabel>
            <TextField
              name="awayteam_goal"
              value={formData.awayteam_goal}
              onChange={handleChange}
              error={!!formErrors.awayteam_goal}
              helperText={formErrors.awayteam_goal}
              sx={{ width: '130px', height: '40px' }}
            />
            <InputLabel sx={{ width: '100px', flexWrap: 'wrap' }}>Match Result
            <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>

            </InputLabel>
            <Stack direction="column" >
              <Select
                labelId="match-result-label"
                name="matchResult"
                value={formData.matchResult}
                onChange={handleChange}
                error={!!formErrors.matchResult}
                helperText={formErrors.matchResult}
                sx={{
                  width: '130px', height: '30px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none !important', // Remove one of the borders
                  },
                  '& .MuiSelect-select': {
                    height: '10px !important',
                    minHeight: '10px !important'
                  }
                }}
              >
                <MenuItem value={'Select'}>Select</MenuItem>
                <MenuItem value={data?.hometeam}>{data?.hometeam}</MenuItem>
                <MenuItem value={data?.awayteam}>{data?.awayteam}</MenuItem>
                <MenuItem value={'Draw'}>Draw</MenuItem>
              </Select>
              {formErrors.matchResult && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {formErrors.matchResult}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" mt={2}>
            <Button
              onClick={handleSave}
              sx={{
                backgroundColor: '#3C50E0',
                color: '#fff',
                px: 6,
                '&:hover': {
                  backgroundColor: '#3C50E0',
                  color: '#fff',
                },
              }}
            >
              {save}
            </Button>
            <Button
              onClick={() => setPopup(false)}
              sx={{ backgroundColor: '#fff', color: '#000', border: '#E2E8F0 1px solid', ml: 2 }}
            >
              {cancel}
            </Button>
          </Stack>
         </> : 
         <>
          <Stack direction="row" spacing={2} alignItems="center" my={5}>
            <InputLabel sx={{ width: '110px', flexWrap: 'wrap' }}> Start Date 
            <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>

            </InputLabel>
            <TextField
              name="start_date"
              type={'date'}
              value={formData.start_date}
              onChange={handleChange}
              error={!!formErrors.start_date}
              helperText={formErrors.start_date}
              inputProps={{ min: new Date().toISOString().split('T')[0] }}
              sx={{ width: '140px', height: '40px' }}
            />
            <InputLabel sx={{ width: '100px', flexWrap: 'wrap' }}>Start Time
            <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>
            </InputLabel>
            <Stack direction="column" >
            <TextField
              name="start_time"
              type={'time'}
              value={formData.start_time}
              onChange={handleChange}
              error={!!formErrors.start_time}
              helperText={formErrors.start_time}
              sx={{ width: '140px', height: '40px' }}
            />
              {/* {formErrors.matchResult && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {formErrors.matchResult}
                </Typography>
              )} */}
            </Stack>
          </Stack>
    
          <Stack direction="row" spacing={2} alignItems="center" my={5}>

            <InputLabel sx={{ width: '110px', flexWrap: 'wrap' }}> Status
              <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>
            </InputLabel>

            <Stack direction="column" >
              <Select
                labelId="status-label"
                name="Status"
                value={formData.Status}
                onChange={handleChange}
                sx={{
                  width: '175px', height: '30px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none !important',
                  },
                  '& .MuiSelect-select': {
                    height: '10px !important',
                    minHeight: '10px !important'
                  }
                }}
              >
                {Status.map(item =>
                  <MenuItem value={item.name}>{item.label}</MenuItem>
                )}
              </Select>
              {/* {formErrors.matchResult && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {formErrors.matchResult}
                </Typography>
              )} */}
            </Stack>

          </Stack>

          <Stack direction="row" mt={2}>
            <Button
              onClick={handleSave}
              sx={{
                backgroundColor: '#3C50E0',
                color: '#fff',
                px: 6,
                '&:hover': {
                  backgroundColor: '#3C50E0',
                  color: '#fff',
                },
              }}
            >
              {save}
            </Button>
            <Button
              onClick={() => setPopup(false)}
              sx={{ backgroundColor: '#fff', color: '#000', border: '#E2E8F0 1px solid', ml: 2 }}
            >
              {cancel}
            </Button>
          </Stack>
         </>
         }
         
        </Box>
      </Box>
    </Modal>
  );
}
