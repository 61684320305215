import AuthWrapper from "./AuthWrapper";
import email_icon from "../../images/email_icon.png";
import {
  Box,
  Button,
  Stack,
  Typography,
  InputAdornment,
} from "@mui/material";
import { TextField, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import _ from "lodash";
import { useStyles } from "./PlaceHolderStyle";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [formdata, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false)
  const sendOTP = async () => {
    try {
      if (!loading) {
        setLoading(true)
        const response = await Axios.post(
          `${base_url}/api/accounts/forgot_password`,
          formdata
        );
        setLoading(false)

        if (response.status === 200) {
          navigate("/auth/verifyOtp", { replace: true, state: { formdata } });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e, setErrors) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name == "email") {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrors({ [name]: "" });
      }
    } else {
      setErrors({ [name]: "" });
    }
  };

  const validate = () => {
    const errors = {};
    if (formdata.email == "") {
      errors.email = "Please enter a valid email address.";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formdata.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  return (
    <AuthWrapper
      title="Forgot your password?"
      subtitle="Please enter the email address you would like to reset password"
    >
      <Box sx={{ mt: 1 }}>
        <Formik initialValues={formdata} validate={validate} onSubmit={sendOTP}>
          {({ errors, touched, setErrors }) => (
            <Form>
              <Box sx={{ mt: 3, borderRadius: 19 }}>
                <Stack direction="column" justifyContent="center">
                  <TextField
                    sx={{ mb: 2 }}
                    name="email"
                    placeholder="Email"
                    className={classes.root}
                    onChange={(e) => handleChange(e, setErrors)}
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={email_icon} alt="icon" />
                          <Typography color="#111111" sx={{ ml: 2 }}>
                            |
                          </Typography>
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: "#0000001A",
                        border: "none",
                        outline: "none",
                      },
                    }}
                  />
                </Stack>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#0081F8" }}
                  sx={{
                    width: "350px",
                    height: "40px",
                    mt: 1,
                    boxShadow: "none",
                    fontSize: "18px",
                    fontWeight: 500
                  }}
                >
                  Send Verification Code
                </Button>
                <Typography sx={{ mt: 3, textAlign: 'center', fontWeight: 500, fontSize: "18px", color: '#0081F8', cursor: "pointer" }} onClick={() => { navigate('/auth/login') }}>
                  Back to login
                </Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </AuthWrapper>
  );
}
