import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import {
  Box,
  IconButton,
  Switch,
  Typography,
  Tab,
  Stack,
  Button,
} from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import {
  setActiveMatchWeek,
  setLoading,
  setPotSetting,
  setSelectedTeam,
  setSelectedUser,
  setUsers,
} from "../../../redux/action";
import { Link, useNavigate, useParams } from "react-router-dom";

import Axios from "axios";
import { base_url } from "../../backendurl/url";

import ModalPopUp from "../../common/TransForm/ModalPopUp";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";

// import FixtureResults from "./FixtureResults";
function CompleteWinnerDetails() {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const { PotSetting,ActiveMatchWeek } = useSelector((state) => state);
  const [loading , setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { id } = useParams("id");
  const userTableColumns = [
    { key: "username", label: "Players", align: "left", fontSize: "15px" },
    {
      key: "winning_amounts",
      label: "Prize Amount",
      align: "right",
      fontSize: "15px",
      valueRenderer: (data) => {
        return `${data['pot_currency']}  ${data['winning_amounts']}`
        }
    },
  ];

  const getWinnerDetails = useCallback(async () => {
    try{
      setLoading(true);

      const response = await Axios.get(
        `${base_url}/api/pot/pots/${id}`,
        Config
        );
  
        const WinnerDetails = await Axios.get(
          `${base_url}/api/pot/potwinners?pot=${id}`,
          Config);
    
        dispatch(setPotSetting(response?.data?.data));
        dispatch(setActiveMatchWeek(WinnerDetails?.data?.data?.players))
        setLoading(false);
    }
    
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
  }, []);

  useEffect(() => {
    getWinnerDetails();
  }, []);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Winner Details"} />

      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round ID:</span> {PotSetting?.id}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Category:</span>  {PotSetting?.category}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League:</span> {PotSetting?.league}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Value:</span>  {PotSetting?.pot_currency} {PotSetting?.current_pot_value}
        </Typography>
      </Box>
      <TransTable
        showSearch={false}
        checkbox={false}
        columns={userTableColumns}
        rows={ActiveMatchWeek}
        showAddBtn={false}
        isLoading={loading}
      />
  
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={"Activating pot ?"}
        subTitle={"Are you sure, you want to activate the pot"}
        save={"Yes, Activate"}
        cancel={"Exit"}
        sx={{ borderRadius: "2px" }}
      />
    <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box>
  );
}

export default CompleteWinnerDetails;
