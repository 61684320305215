import { InputAdornment } from "@mui/material";
import lockicon from "../../../../images/lockIcon.png";
import { useState } from "react";


export default function FormLeagues(values) {
    return [
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
        },
        children: [
            {
                shape: "input",
                type: "select",
                name: "league",
                // handleChange: handleChange,
                className: "border",
                title: "League",
                label: "League",
                options: values.leagues,
                placeholder: "",
                width: "100%",
                validation: {
                  required: true,
                },
              },
            {
            shape: "input",
            type: "select",
            name: "season_year",
            // handleChange: handleChange,
            className: "border",
            title: "Season",
            label: "Season",
            options: values.seassionYear,
            placeholder: "id",
            width: "100%", 
            validation: {
              required: true,
            },
          }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
        },
        children: [
          {
            shape: "input",
            type: "text",
            name: "name",
            // handleChange: handleChange,
            className: "border",
            title: "League Season Display Name",
            label: "League Season Display Name",
            placeholder: "Enter Match week name",
            width: "100%",
            validation: {
              required: true,
            },
          },
          // {
          //   shape: "input",
          //   type: "file",
          //   name: "league_logo",
          //   // handleChange: handleChange,
          //   uploadType: 'inlineDocument',
          //   mode:'view',
          //   handleChange: "",
          //   className: "border",
          //   title: "logo",
          //   label: "League Logo",
          //   placeholder: "League Logo",
          //   fileRestriction : {height:200,width:200},
          //   width: "100%",
          //   accept:'image/*',
          //     validation: {
          //       required: true,
          //     },
          // }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
        children: [
          {
            shape: "button",
            type: "action",
            title: 'Fetch Details',
            // submitButton : true,
            isDisabled: !values.isSystemLeagueSelected,
            name: "Add",
            sx : {mr:2,
             },
            validation: {
              hiddenRules: [
                {
                  mode: "view",
                },
              ],
             
            },
          },        
        ],
      },
    
    ];
  }
  