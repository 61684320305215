import { Box } from "@mui/material";
import { TransForm } from "../common/TransForm/Index";
import configNewTeam from "../common/jsons/formConfigs/configuringNewTeam";
import { PageTitle } from "../common/PageTitle";
import Divider from '@mui/material/Divider';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { base_url } from "../backendurl/url";
import Axios from "axios";
import { Config } from "../../utils/commonFuctions/Token";
import { FirebaseImageUpload } from "../common/FirebaseImageUpload";
import { S3ImageUpload } from "../common/S3ImageUpload";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export const NewTeamForm = ({ mode }) => {

  const [popup, setPopup] = useState(false)
  const [leagues, setLeaguesData] = useState([])
  const [systempopup, setSystemPopup] = useState('')
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { selectedTransporter } = useSelector(
    (state) => state
  );
  const navigate = useNavigate();
  const [teamdata, setTeamData] = useState({});


  const onSubmit = async (data) => {

    if(base_url == process.env.REACT_APP_DEV || base_url == process.env.REACT_APP_TEST){
       const imageUrl =  await FirebaseImageUpload(data?.logo)
       data.logo = imageUrl.split('&')[0]
    }
    
    if(base_url == process.env.REACT_APP_AWS_PROD){
      const imageUrl = await S3ImageUpload(data?.logo)
      data.logo = imageUrl.location
    }
    
    setTeamData(data)

    const league = leagues.filter(item => item.id == data.league)
    if (league.length) {
      league[0].system_league ? setSystemPopup('Are you sure to add manual team in system created league')
        : setSystemPopup('Are you sure, you want to create a new team with given details')
    }
    setPopup(true)
  };


  const getTeamLeagues = async () => {
    try{
      const response = await Axios.get(`${base_url}/api/pot/configureleagues?status=true`, Config)
      let leaquename = response?.data?.data.map(item => {
        return {
          id: item?.league_id,
          name: item?.name,
          ...item
        }
      })
      setLeaguesData(leaquename)
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification) 
   }
  }


  useEffect(() => {
    getTeamLeagues();
  }, []);


  const SubmitTeams = async () => {
    try {
      const response = await Axios.post(`${base_url}/api/pot/teams`, teamdata, Config)
      setPopup(false)
      navigate('/configurations/teams')  
    }
    catch(error) {
      RefreshToken(error,setErrorMessage,setnotification) 
    }
    
  
  }

  return (
    <Box  >
      <Box sx={{ px: 6, py: 3 }} alignContent="center">
        <PageTitle pageTitle={'Configuring New Team'} />
        <Typography>Enter the data below to configure a new team</Typography>
      </Box>
      <Divider sx={{ mb: 2, height: 4 }} />

      <TransForm
        submitAction={onSubmit}
        data={selectedTransporter}
        mode={mode}
        formInputs={[
          ...configNewTeam({
            leagues
          }),
        ]}
      />
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Confirm the action ?'}
        subTitle={systempopup}
        onClick={SubmitTeams}
        save={'Yes, Create'}
        cancel={'Exit'}
      />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box>
  );
};
