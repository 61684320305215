import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading, setSelectedUser, setUsers } from "../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "../common/Iconify";
import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import { Dateformater } from "../common/TransForm/dateFormater";
import editicon from "../../images/editIcon.png";
import ModalPopUp from "../../components/common/TransForm/ModalPopUp";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export default function ChangePlayerDetails() {
  const navigate = useNavigate();
  const { users } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [popup, setpopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)
  const [userId, setUserId] = useState("");

  const userTableColumns = (params) => [
    {
      key: "serial_no",
      label: "Player ID",
      align: "left",
      fontSize: "15px",
    },
    { key: "username", label: "User Name", align: "left", fontSize: "15px" },
    { key: "first_name", label: "Name", align: "left", fontSize: "15px" },
    { key: "email", label: "Email", align: "left" },
    { key: "mobile_number", label: "Mobile", align: "left", fontSize: "15px" },
    {
      key: "last_played",
      label: "Last Played Date",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        if (data.last_played == null) {
          return <Typography> N/A</Typography>;
        } else {
          return <Typography>{Dateformater(data["last_played"])}</Typography>;
        }
      },
    },
    {
      key: "available_tokens",
      label: "Available Token",
      align: "left",
      fontSize: "15px",
    },
    { key: "pots_count", label: "Total Pots", align: "left", fontSize: "15px" },
    {
      key: "active_pots_count",
      label: "Active Pots",
      align: "left",
      fontSize: "15px",
    },
    {
      key: "gainlostamount",
      label: "Total Gain/Loss",
      align: "left",
      fontSize: "15px",
    },
    {
      key: "is_active",
      label: "Status",
      align: "center",
      fontSize: "14px",
      valueRenderer: (data) => {
        if (data.is_active == true) {
          return (
            <Capsule
              data="Active"
              bgColor="#21965333"
              color="#219653"
              sx={{ width: "130px" }}
            />
          );
        } else {
          return (
            <Capsule
              data="Inactive"
              bgColor="#87878714"
              color="#64748B"
              sx={{ width: "130px" }}
            />
          );
        }
      },
    },
    {
      key: "Status",
      label: "Actions",
      align: "center",
      valueRenderer: (data) => {
        return (
          <Box sx={{ width: "180px" }}>
            <IconButton
              sx={{
                "&:hover": {
                  // backgroundColor: 'transparent'
                  borderRadius: "12px",
                },
              }}
              onClick={() => {
                setUserId(data);
                setpopup(true);
              }}
            >
              <Typography
                sx={{
                  color: "#3C50E0",
                  display: "flex",
                  gap: 2,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Change Status
                <Iconify
                  icon={editicon}
                  type="image"
                  size="small"
                  color="primary.main"
                />
              </Typography>
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const getUsers = useCallback(async () => {
    try{
      if (!loading) {
        setLoading(true);
        const response = await Axios.get(
          `${base_url}/api/accounts/user/create?type=player`,
          Config
        );
        if (response?.status == 200) {
          let no = 1;
          let data = response?.data?.data;
          data.map((item) => {
            item.serial_no = no;
            no += 1;
          });
          dispatch(setUsers(data));
        }
        setLoading(false);
      }
    }
  catch(error){
    RefreshToken(error,setErrorMessage,setnotification)  
  }
  }, [users]);

  const gotoAddUser = () => {
    navigate("add");
    dispatch(setSelectedUser({}));
  };

  const userNamediv = () => {
    return (
      <>
        Are you sure, you want to deactivate <b>{userId?.username} </b> ?
      </>
    );
  };
  const deactiveUser = async () => {

    try{
      const response = await Axios.put(
        `${base_url}/api/accounts/user/${userId.id}`,
        { ...userId, is_active: userId.is_active ? false : true },
        Config
      );
      if (response?.data?.status == "Success") {
        navigate(-1);
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)  
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Box>
        <TransTable
          pageTitle="Change Player Status"
          showAddBtn={false}
          // addBtnTitle = 'Change Status'
          showSearch={true}
          selecteable={false}
          checkbox={false}
          columns={userTableColumns()}
          rows={users}
          filter_keys={["username", "mobile_number", "email", "is_active"]}
          addBtnAction={gotoAddUser}
        />
         <Notification
        message={errorMessage}
        setopen={setnotification}
        type={"error"}
        show={notification}
      />
      </Box>
      <ModalPopUp
        setPopup={setpopup}
        openpopup={popup}
        title={userId.is_active ? "Deactivating User ? " : "Activating User ?"}
        subTitle={userNamediv()}
        textArea={true}
        saveBtnBg={"red"}
        onClick={deactiveUser}
        save={userId.is_active ? "Deactivate" : "Activate"}
        cancel={"Exit"}
      />

      <Button></Button>
    </>
  );
}
