import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import {
  Box,
  IconButton,
  Switch,
  Typography,
  Tab,
  Stack,
  Button,
} from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import {
  setActiveMatchWeek,
  setLoading,
  setPotSetting,
  setSelectedTeam,
  setSelectedUser,
  setUsers,
} from "../../../redux/action";
import { Link, useNavigate, useParams } from "react-router-dom";

import Axios from "axios";
import { base_url } from "../../backendurl/url";
import Excel from "../../../images/Excel.svg";
import ModalPopUp from "../../common/TransForm/ModalPopUp";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
// import FixtureResults from "./FixtureResults";

function CompletepotsId() {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false)
  const [value, setValue] = useState("a");
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { selectedTeam,PotSetting,ActiveMatchWeek } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams("id");


  // File Extension and Type for excel download
  const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  const userTableColumns = [
    { key: "matchweek", label: "Match Week Name", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
        return (
          <Button
            component={Typography}
            style={{ color: "#3C50E0", textDecoration: "underline" }}
            onClick={() => navigate(`/rounds/completeround/matchweek/${data?.id}`,{state:{matchweek:data?.matchweek,pot_id:id}})}
          >
            {data["matchweek"]}
          </Button>
        );
      },
 },
    { key: "prediction_count", label: "No. of Predictions", align: "left", fontSize: "15px" },
    { key: "winning_count", label: "No. of Winners", align: "left", fontSize: "15px" },

  ];

  const getCompletePotId = useCallback(async () => {
    try{
      if (!loading) {
      
        setLoading(true);
        dispatch(setPotSetting([]));
        dispatch(setActiveMatchWeek([]));
        const response = await Axios.get(
          `${base_url}/api/pot/pots/${id}`,
          Config
        );
        if (response?.status == 200) {
          const matchweek = await Axios.get(
            `${base_url}/api/pot/potmatchweeks?&pot=${id}&season=${response?.data?.data?.season}`,
            Config
          );
          dispatch(setPotSetting(response?.data?.data));
          dispatch(setActiveMatchWeek(matchweek?.data?.data));
          setLoading(false);
      }
    }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
   }, []);

  //  const downloadExcel = async (jsonData,fileName) => {

  //   const filteredData = jsonData.map((item) => {
  //     const newItem = {};
  //     userTableColumns.forEach((col) => {
  //       // if (col.valueRenderer) {
  //         // newItem[col.label] =  item[col.key];
  //       // } else {
  //         newItem[col.label] = item[col.key];
  //       // }
  //     });
  //     return newItem;
  //   });

  //   const ws = XLSX.utils.json_to_sheet(filteredData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });

  //   FileSaver.saveAs(data, fileName + fileExtension);
  //  }

  useEffect(() => {
    getCompletePotId();
  }, [value]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Round ID Details"} />
        {/* <Box sx={{ marginLeft: "10px", cursor: 'pointer' }}>
          <img width={'50px'} src={Excel} alt="excel" onClick={()=>downloadExcel(ActiveMatchWeek,"Completed_Rounds")} />
        </Box> */}
      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round ID:</span> {PotSetting?.id}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Category:</span>  {PotSetting?.category}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League:</span>  {PotSetting?.league}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Value:</span>{PotSetting?.pot_currency} {PotSetting?.current_pot_value}
        </Typography>
      </Box>
          <TransTable
            showSearch={false}
            checkbox={false}
            columns={userTableColumns}
            rows={ActiveMatchWeek}
            showAddBtn={false}
            isLoading={loading}
          />
          <Box sx = {{display : 'flex',px:6}}>
          </Box>
          <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box>
    
  );
}

export default CompletepotsId;
