import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import {
  Box,
  IconButton,
  Switch,
  Typography,
  Tab,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setSelectedTeam,
  setSelectedUser,
  setUsers,
} from "../../redux/action";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Iconify from "../../common/Iconify";

import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";
// import FixtureResults from "./FixtureResults";
function PlayersIdDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;

const [popup, setPopup] = useState(false)
  const [value, setValue] = useState("a");
  const { selectedTeam } = useSelector((state) => state);
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const gotoAddUser = () => {
    navigate("add");
  };

  const userTableColumns = [
    { key: "matchweek", label: "Match Week Name", align: "left", fontSize: "15px" },
    { key: "match", label: "Match Selected", align: "left", fontSize: "15px" },
    { key: "pick", label: "Picks", align: "left", fontSize: "15px" },
    { key: "player_status", label: "Player Status", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      if (data.player_status == "Win") {
        return <Capsule data='Win' bgColor='#21965333' color='#219653'  />;
      } else if (data.player_status == "Lost") {
        return <Capsule data='Lost' bgColor='#96592114' color='#965921'  />;
      } else if (data.player_status == "Active") {
        return <Capsule data='Active' bgColor='#21965333' color='#219653' />;
      } else if(data.player_status == "JackPot") {
        return <Capsule data='JackPot' bgColor='#21965333' color='#219653'  />;
      }
      },
 },  ];


  const getUsers = useCallback(async () => {
    try{
      if (!loading) {

        setLoading(true);
        const response = await Axios.get(
          `${base_url}/api/pot/potmatchweekplayers?player_id=${state?.player_details?.id}&pot=${state?.data?.pot_id}`,
          Config
        );
  
        if (response?.status == 200) {
          dispatch(setSelectedTeam(response?.data?.data));
        }
        setLoading(false);
      }
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
   }
  }, [value]);

  const SubmitChange = async () => {

    // const response = await Axios.patch(`${base_url}/api/pot/configureleagues/${leaguedetails.id}`, { active: !leaguedetails.active, name: leaguedetails.name }, Config);
    // if (response?.status == 200) {
    //   setPopup(false)
    // }
    // getConfig()
  }

 

  useEffect(() => {
    getUsers();
  }, [value]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Player Details"} />

        {/* <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB' }} onClick={gotoAddUser} >Add new league +</Button> */}
      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "1%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Player ID:</span> {state.player_details.serial_no}
        </Typography>
        <Typography sx={{ pr: "1%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>User Name:</span> {state.player_details.username}
        </Typography>
        <Typography sx={{ pr: "1%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Name:</span> {state.player_details.username}
        </Typography>
        <Typography sx={{ pr: "1%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round ID:</span> {state.data.pot_id}
        </Typography>
        <Typography sx={{ pr: "1%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Category:</span> {state.data.category}
        </Typography>
        <Typography sx={{ pr: "1%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League:</span> {state.data.leaguename}
        </Typography>


      </Box>
      <Divider sx = {{width:'92%',pt:4,margin:'auto'}}/>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        
        <PageTitle pageTitle={"List of Match Week"} />
      </Stack>
          <TransTable
            showSearch={false}
            checkbox={false}
            columns={userTableColumns}
            rows={selectedTeam}
            showAddBtn={false}
          />
          <Box sx = {{display : 'flex',px:6}}>
                {/* <TransButton title={'Activate'} onClick={potActivate}/>
                <TransButton onClick = {() => navigate('/pots')} title={'Back'} sx = {{background : '#fff',color : '#1c2434',border : '1px solid #E2E8F0','&:hover': {
      backgroundColor: 'transparent', // Remove the background color change on hover
    },}}  /> */}

          </Box>
           <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Activating pot ?'}
        subTitle={'Are you sure, you want to activate the pot'}
        onClick={SubmitChange}
        save={'Yes, Activate'}
        cancel={'Exit'}
        sx = {{borderRadius:'2px'}}
      />
      <Notification
        message={errorMessage}
        setopen={setnotification}
        type={"error"}
        show={notification}
      />
    </Box>
    
  );
}

export default PlayersIdDetails;
