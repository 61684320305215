import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setPotSetting, setPotSettingList } from "../../redux/action";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Capsule from '../common/Capsule'
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import Edit from '../../images/editIcon.svg'
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export default function PotSetting({ league, season }) {
  const navigate = useNavigate();
  const { PotSettingList } = useSelector((state) => state);
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();

  const potSettingColumn = () => [
    {
      key: "id",
      label: "Round Category ID",
      align: "left",
      fontSize: '15px',
    },
    { key: "pot_category", label: "Round Category", align: "left", fontSize: "15px" },
    { key: "description", label: "Round Description", align: "left", fontSize: "15px" },
    {
      key: "active",
      label: "Status",
      align: "center",
      fontSize: '14px',
      valueRenderer: (data) => {
        if (data.status == 'Launched') {
          return <Capsule data='Launched' bgColor='#21965314' color='#219653' />;
        } else {
          return <Capsule data='Saved' bgColor='#96592114' borderColor='#965921' color='#965921' />;
        }
      },
    },
    {
      key: "action",
      label: "Action",
      align: "center",
      valueRenderer: (data) => {
        return (
          <Box>
            <Typography
              sx={{ color: '#3C50E0', fontSize: '14px', borderRadius: '6px' }}
              onClick={() => editpotForm(data,data.status)}
            >
              {/* {data.status == 'Launched' ? '' : */}
                <img src={Edit} alt='saved' style={{margin:'auto'}} />
                {/* } */}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const getPotsetting = useCallback(async () => {
    try{
      const response = await Axios.get(`${base_url}/api/pot/potconfigs?season=${league}`, Config);
      if (response?.status == 200) {
        dispatch(setPotSettingList(response?.data?.data))
        dispatch(setPotSetting({}))
      }
    }
  catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
  }
  }, []);

  const gotoAddUser = () => {
    navigate("add", { state: { league: league, season: season } });
  };
  const editpotForm = (data,type) => {
    navigate(`edit/${data.id}`, { state: { league: league, season: season,type:type,pot_id:data.id } });
    dispatch(setPotSetting(data))

  };

  useEffect(() => {
    getPotsetting();
  }, []);

  return (
    <>
      <Box  >
        <TransTable

          addBtnTitle={'Create New Round Category +'}
          showSearch={false}
          checkbox={false}
          columns={potSettingColumn()}
          rows={PotSettingList}
          addBtnAction={gotoAddUser}
          btnposition={'bottom'}
        />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
    </>
  );
}
