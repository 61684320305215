
import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TextField, Button, Paper, IconButton, Select, MenuItem, Input, Stack, Box, Typography } from '@mui/material';
import Editicon from '../../../images/tableEdit.png'
import Deleteicon from '../../../images/deleteIcon.png'
import Saveicon from '../../../images/tableAddIcon.png'
import { localTimeWithZoneToUTCTime } from './Localdateformater';
import { json } from 'react-router-dom';


const EditableTableGrid = ({ headers, jsonData, onUpdateData, editableColumns, showAdd }) => {


    const [rowData, setRowData] = useState([]);

    const [editableRow, setEditableRow] = useState(-1);
    const [newRowData, setNewRowData] = useState({});
    const [prevData,setPrevData] = useState({})
    const [isAdding, setIsAdding] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (jsonData && jsonData.length > 0) {
            setRowData(jsonData);
        }
    }, [jsonData]);

    const handleEditClick = (rowIndex) => {
        let string = JSON.stringify(rowData[rowIndex])
        setPrevData(JSON.parse(string))
        setEditableRow(rowIndex);
    };

    const handleDeleteClick = (rowIndex) => {
        const updatedData = [...rowData];
        updatedData.splice(rowIndex, 1);
     
        setRowData(updatedData);
        onUpdateData(updatedData);
    };

    const handleCellChange = (name, value, rowIndex, colIndex) => {
        const updatedData = [...rowData];
        updatedData[rowIndex][name] = value;
        setRowData(updatedData);
    };

    const handleSaveClick = () => {
        const errorObj = {};
        Object.keys(rowData[editableRow]).forEach(key => {

            if (rowData[editableRow][key] === '' && key !='fixture_id') {
                errorObj[key] = 'Field is required.';
            }
        });
        if (Object.keys(errorObj).length > 0) {
            setErrors(errorObj);
        } else {
            rowData[editableRow].start = (rowData[editableRow].start).replace(/\d{2}:\d{2}/, (rowData[editableRow].time))
            rowData[editableRow].start = localTimeWithZoneToUTCTime(rowData[editableRow].start)
            rowData[editableRow].end = new Date(new Date(rowData[editableRow].start).getTime() + 2 * 60 * 60 * 1000).toISOString()
            // let hometeam = 

            setEditableRow(-1);
            onUpdateData(rowData);
            setErrors({});
        }
    };

    const handleAddClick = () => {
        setIsAdding(true);
        setShowCancelButton(true);
        const initialNewRowData = {};
        headers.forEach((header) => {
            initialNewRowData[header.name.toLowerCase()] = ''; // Include all headers in the new row data
        });
        setNewRowData(initialNewRowData);
    };

    const handleNewRowChange = (value, fieldName) => {
        setNewRowData((prevData) => ({ ...prevData, [fieldName.toLowerCase()]: value }));
    };

    const handleAddNewRow = () => {
        // const isFormFilled = Object.values(newRowData).every((value) => value.trim() !== '');
        // if (isFormFilled) {
           

        const errorObj = {};
        Object.keys(newRowData).forEach(key => {
            if (newRowData[key] === '') {
                errorObj[key] = 'Field is required.';
            }
            else if(key=='awayteam'&&newRowData.hometeam==newRowData.awayteam){
                errorObj[key] = 'home team cannot be the same.';
            }
        });

        if (Object.keys(errorObj).length > 0) {
            setErrors(errorObj);
        } else {

            newRowData.start = newRowData.start + 'T' + newRowData.time + ':00Z'
            newRowData.start = localTimeWithZoneToUTCTime(newRowData.start)
            newRowData.end = new Date(new Date(newRowData.start).getTime() + 2 * 60 * 60 * 1000).toISOString()
            const updatedData = [...rowData, newRowData];
            let options = headers.find(item=>item.name=='hometeam')
            options = options?options?.optionJson : []
            let hometeam = options.find(item=>item.name==newRowData?.hometeam)
            newRowData.hometeam_logo = hometeam.logo

            let awayteam = options.find(item=>item.name==newRowData?.awayteam)
            newRowData.awayteam_logo = awayteam.logo
            setRowData(updatedData);
            onUpdateData(updatedData);
            setIsAdding(false);
            setErrors({});

        }
        // else {
        //     alert('Please fill all fields before adding.');
        // }
    };

    const handleCancelClick = (rowIndex) => {
        setIsAdding(false);
        setShowCancelButton(false);
        if(rowIndex != undefined){
            setEditableRow(-1)
            const updatedData = [...rowData];
            updatedData[rowIndex] = prevData;
            setRowData(updatedData);
        }else{
            setNewRowData({})
        }
    };


    const formattedValue = (value) => {
        return value?.includes('Z') ? new Date(value.replace('Z', '')).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) : value;
    }
    // const renderInputComponent = (type, value, onChangeHandler, options = []) => {

    //     switch (type) {
    //         case 'select':
    //             return (
    //                 <Select
    //                     sx={{
    //                         width: '150px',
    //                         // height: '0px !important',
    //                         padding: '0px 0px',
    //                         '& .MuiOutlinedInput-notchedOutline': {
    //                             border: 'none !important', // Remove one of the borders
    //                         },
    //                         '& .MuiSelect-select': {
    //                             height: '10px !important',
    //                             minHeight:'10px !important'
    //                         }
    //                     }}
    //                     value={value} onChange={(e) => onChangeHandler(e.target.value)}>
    //                     {options.map((option) => (
    //                         <MenuItem key={option} value={option}>
    //                             {option}
    //                         </MenuItem>
    //                     ))}
    //                 </Select>
    //             );
    //         case 'text':
    //             return <TextField value={value} onChange={(e) => onChangeHandler(e.target.value)} sx={{ width: '150px' }} />;
    //         case 'date':
    //             return (
    //                 <TextField
    //                     type="date"
    //                     value={value ? new Date(value)?.toISOString()?.slice(0, 10) : value}
    //                     onChange={(e) => onChangeHandler(e.target.value)}
    //                     sx={{ width: '150px' }}
    //                 />
    //             );
    //         case 'time':
    //             return (
    //                 <TextField
    //                     type="time"
    //                     value={formattedValue(value)}
    //                     onChange={(e) => onChangeHandler(e.target.value)}
    //                     sx={{ width: '150px' }}
    //                 />
    //             );
    //         default:
    //             return null;
    //     }
    // };

    const renderInputComponent = (type, value, onChangeHandler, options = [], error) => {
        switch (type) {
            case 'select':
                return (
                    <div>
                        <Select
                            sx={{
                                width: '150px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none !important',
                                },
                                '& .MuiSelect-select': {
                                    height: '10px !important',
                                    minHeight: '10px !important'
                                }
                            }}
                            value={value} onChange={(e) => onChangeHandler(e.target.value)}>
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </div>
                );
            case 'text':
                return (
                    <div>
                        <TextField value={value} onChange={(e) => onChangeHandler(e.target.value)} sx={{ width: '150px' }} />
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </div>
                );
            case 'date':
                return (
                    <div>
                        <TextField
                            type="date"
                            value={value ? new Date(value)?.toISOString()?.slice(0, 10) : value}
                            onChange={(e) => onChangeHandler(e.target.value)}
                            sx={{ width: '150px' }}
                        />
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </div>
                );
            case 'time':
                return (
                    <div>
                        <TextField
                            type="time"
                            value={formattedValue(value)}
                            onChange={(e) => onChangeHandler(e.target.value)}
                            sx={{ width: '150px' }}
                        />
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ mb: 0, px: 6, pt: 3, pb: 0 }}>
            <TableContainer component={Paper} sx={{ border: 'none' }} >
                <Table sx={{ border: 'none' }}>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
                            {headers?.map((header, index) => (
                                <TableCell key={index}>{header.lable}</TableCell>
                            ))}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {rowData?.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {Object.values(row)?.map((cell, colIndex) => {
                                    return headers[colIndex]?.show && <TableCell key={colIndex}>
                                        {editableRow === rowIndex && editableColumns.includes(colIndex) ? (
                                            renderInputComponent(
                                                headers[colIndex]?.type,
                                                cell,
                                                (newValue) => handleCellChange(headers[colIndex]?.name, newValue, rowIndex, colIndex),
                                                headers[colIndex]?.options // Pass options for select inputs
                                            )
                                        ) : (
                                            headers[colIndex]?.valueRenderer ? headers[colIndex]?.valueRenderer(row, rowIndex) : cell
                                        )}
                                    </TableCell>
                                })}

                                <TableCell sx={{ width: '100px' }}>
                                    {editableRow === rowIndex ? (
                                        <Stack direction={'row'} sx={{ width: '130px' }} >
                                            <Button onClick={handleSaveClick} >
                                                <img src={Saveicon} alt='icon' style={{ width: '20px', height: '20px', paddingRight: '3px' }} />
                                            </Button>
                                            <Button onClick={handleCancelClick}>
                                                <img src={Deleteicon} alt='icon' style={{ width: '20px', height: '20px', paddingLeft: '3px' }} />
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <Stack direction={'row'} sx={{ width: '130px' }} >
                                            <Button onClick={() => handleEditClick(rowIndex)} >
                                                <img src={Editicon} alt='icon' style={{ width: '20px', height: '20px', paddingRight: '3px' }} />
                                            </Button>
                                            <Button onClick={() => handleDeleteClick(rowIndex)} >
                                                <img src={Deleteicon} alt='icon' style={{ width: '20px', height: '20px', paddingLeft: '3px' }} />
                                            </Button>

                                        </Stack>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))} */}
                        {rowData?.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {headers?.map((header, colIndex) => (
                                    header.show && (
                                        <TableCell key={colIndex}>
                                            {editableRow === rowIndex && editableColumns.includes(colIndex) ? (
                                                renderInputComponent(
                                                    header.type,
                                                    row[header.name.toLowerCase()],
                                                    (newValue) => handleCellChange(header.name, newValue, rowIndex, colIndex),
                                                    header.options,
                                                    null,
                                                    header.min,
                                                    header.max,
                                                )
                                            ) : (
                                                header.valueRenderer ? header.valueRenderer(row) : row[header.name.toLowerCase()]
                                            )}
                                        </TableCell>
                                    )
                                ))}
                                <TableCell sx={{ width: '100px' }}>
                                    {editableRow === rowIndex ? (
                                        <Stack direction={'row'} sx={{ width: '130px' }} >
                                            <Button onClick={handleSaveClick} >
                                                <img src={Saveicon} alt='icon' style={{ width: '20px', height: '20px', paddingRight: '3px' }} />
                                            </Button>
                                            <Button onClick={()=>handleCancelClick(rowIndex)}>
                                                <img src={Deleteicon} alt='icon' style={{ width: '20px', height: '20px', paddingLeft: '3px' }} />
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <Stack direction={'row'} sx={{ width: '130px' }} >
                                            <Button onClick={() => handleEditClick(rowIndex)} >
                                               
                                                <img src={Editicon} alt='icon' style={{ width: '20px', height: '20px', paddingRight: '3px' }} />
                                            </Button>
                                            <Button onClick={() => handleDeleteClick(rowIndex)} >
                                                <img src={Deleteicon} alt='icon' style={{ width: '20px', height: '20px', paddingLeft: '3px' }} />
                                            </Button>

                                        </Stack>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {isAdding && (
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell key={index}>
                                        {renderInputComponent(
                                            header.type,
                                            newRowData[header?.name?.toLowerCase()] || '',
                                            (newValue) => handleNewRowChange(newValue, header.name),
                                            header?.options
                                        )}
                                        {header.name in errors && <Box mt={2}>
                                            <Typography key={index} variant="body2" color="error">
                                                {`${header.name}: ${errors[header.name]}`}
                                            </Typography>
                                        </Box>}

                                    </TableCell>

                                ))}
                                <TableCell>
                                    <Button onClick={handleAddNewRow} >
                                        <img src={Saveicon} alt='icon' style={{ width: '20px', height: '20px', paddingRight: '3px' }} />
                                    </Button>
                                    <Button onClick={()=>handleCancelClick()}>
                                        <img src={Deleteicon} alt='icon' style={{ width: '20px', height: '20px', paddingLeft: '3px' }} />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

            </TableContainer>

            {showAdd && !isAdding && (
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 5, position: 'sticky', right: '0' }}>
                    <Button
                        style={{ backgroundColor: '#F1F5F9', color: '#3C50E0', justifySelf: 'flex-end', display: 'flex' }}
                        onClick={handleAddClick}
                    >
                        Add
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default EditableTableGrid;


