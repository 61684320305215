import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../../service";
import { Config } from "../../../utils/commonFuctions/Token";

import {
  setLoading,
  setPotSetting,
  setPotSettingList,
  setSelectedLeagues,
  setUsers,
  setWaitinglistpot,
} from "../../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import { useState } from "react";
import UpdatePopup from "../../Leagues/UpdatePopup";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";

export default function WaitlistPots() {
  const navigate = useNavigate();
  const { PotSettingList } = useSelector((state) => state);
  const [notification, setnotification] = useState(false)
  const [loading,setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const PotListColumn = () => [
    {
      key: "id",
      label: "Round ID",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        return (
          <Link
            component={Typography}
            style={{ color: "#3C50E0", textDecoration: "underline" }}
            to={`/rounds/view/${data.id}`}
            onClick={() => dispatch(setWaitinglistpot(data))}
          >
            {data["id"]}
          </Link>
        );
      },
    },
    { key: "category", label: "Round Category", align: "left", fontSize: "15px" },
    { key: "league", label: "League", align: "left", fontSize: "15px" },
    {
      key: "min_pot_value",
      label: "Min Round Value",
      align: "left",
      fontSize: "15px",
    valueRenderer: (data) => {
      return `${data['pot_currency']}  ${data['min_pot_value']}`
      }
    },
    {
      key: "current_pot_value",
      label: "Round Value",
      align: "left",
      fontSize: "15px",
    valueRenderer: (data) => {
      return `${data['pot_currency']}  ${data['current_pot_value']}`
      }
    },
    { key: "admin_fee", label: "Admin Fee %", align: "left", fontSize: "15px" ,
  
  },

    {
      key: "joined_picks",
      label: "No. of Picks Joined",
      align: "center",
      fontSize: "14px",
      px: "10px",
    },

    {
      key: "current_match_week",
      label: "Current MW",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        return `${data?.match_weeks?.current_match_week==null ? '-' :data?.match_weeks?.current_match_week  }`
        }
    },
  ];


  const getWaitlistPot = useCallback(async () => {
    setLoading(true)
    dispatch(setPotSettingList([])); 
    try{
      const response = await Axios.get(`${base_url}/api/pot/pots?status=Waitlist`,Config);
      if (response?.status == 200) {
        dispatch(setPotSettingList(response?.data?.data));
        dispatch(setPotSetting({}));
        setLoading(false)
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
  }, []);

  const gotoAddUser = () => {
    navigate("add");
  };

  useEffect(() => {
    getWaitlistPot();
  }, []);

  return (
    <>
      <Box>
        <TransTable
          showSearch={false}
          checkbox={false}
          columns={PotListColumn()}
          rows={PotSettingList}
          addBtnAction={gotoAddUser}
          isLoading={loading}
          filter_keys={[
            "category",
            "league",
            "min_pot_value",
            "current_pot_value",
            "max_active_picks_voting"
          ]}
        />
    <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
    </>
  );
}
