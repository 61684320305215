import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../redux/action";

export const Notification = ( {show=false, type, message ,setopen}) => {
  // const {
  //   notification: { show, type, message },
  // } = useSelector((state) => state);
  // const dispatch = useDispatch();
  

  const handleClose = () => {
    setopen(false)
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={show}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert severity={'error'} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
