import React from "react";
import Box from "@mui/material/Box";
import _ from "lodash";

const Capsule = ({ data, bgColor, color ,borderColor,sx,onClick}) => {
  return (
    <Box
    onClick={onClick}
      sx={{
        bgcolor: bgColor || "secondary.lighter",
        color: color || "secondary.main",
        height: 30,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "22px",
        borderRadius: 11,
        textAlign: "center",
        paddingTop: 0.5,
        borderColor:borderColor ||   "secondary.lighter",
        ...sx,
      
      }}
    >
      {_.startCase(data)}
    </Box>
  );
};

export default Capsule;
