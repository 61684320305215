import React from 'react'
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/action";
import { useSelector } from "react-redux";
import _ from "lodash";
import AuthWrapper from "./AuthWrapper";
import { useState } from "react";
import { base_url } from "../backendurl/url";
import Axios from "axios";
import { MuiOtpInput } from 'mui-one-time-password-input'

export default function VerifyOTP() {



  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('')
  let { userInfo } = useSelector((state) => state);
  const sendOTP = async () => {
    setLoading(true);
    const response = await Axios.post(
      `${base_url}/api/accounts/verifyotp`,
      { email: location.state.formdata.email, otp: otp }
    );
    setLoading(false);
    if (response.status == 200)
      navigate("/auth/updateNewPassword", { replace: true, state: { token: response?.data?.data?.token, email: location.state.formdata.email } });
    setError(response?.data?.data?.message)
  };


  const [otp, setOtp] = useState('')


  const handleChange = (newValue) => {
    setOtp(newValue)
  }


  const resendCode = async () => {
    setLoading(true)
    const response = await Axios.post(
      `${base_url}/api/accounts/forgot_password`,
      location.state.formdata
    );
    setLoading(false)
  }

  return (
    <AuthWrapper
      title="Enter Code"
      subtitle={`Please enter the verification code that we sent to ${location?.state?.formdata?.email}`}
    >
      <>
        <Typography fontSize={18} color="#000" fontWeight={700} mt={0}>
          {userInfo.email}
        </Typography>

        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            sx={{ display: 'flex', justifyContent: 'center', width: '260px', paddingLeft: '0 !important', textAlign: 'center' }}
            numInputs={4}
          />


          {error ? <Typography sx={{ color: 'red', mt: '10px' }}>Invalid OTP</Typography> : ''}
          <Button
            variant="contained"
            fullWidth
            onClick={sendOTP}
            style={{ backgroundColor: '#0081F8', marginTop: 20, boxShadow: 'none', width: '350px' }}
          >
            Verify Code
          </Button>

          <Typography sx={{ mt: 5 }}>Didn’t receive code? <span style={{ color: '#0081F8', cursor: 'pointer' }} onClick={() => resendCode(false)}>Resend code</span> </Typography>
        </Box>

      </>
    </AuthWrapper>
  );
}
