export function utcTimetoLocalTimeWithzone(time) {
  const utcTimeString = time;
  const utcTime = new Date(utcTimeString);
 
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 
  const localTime = new Date(
    utcTime.toLocaleString("en-US", { timeZone: userTimezone })
  );
  let localTimeWithZone = localTime.toLocaleString()
  return localTimeWithZone
}

export function localTimeWithZoneToUTCTime(time) {
  const localTimeString = time;
  const localTime = new Date(localTimeString);
 
  // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 
  const utcTime = new Date(
    localTime.toLocaleString("en-US", { timeZone: "UTC" })
  );
  
  return utcTime.toISOString(); // Return UTC time in ISO format
}
