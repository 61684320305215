import { Box } from "@mui/material";
import { TransForm } from "../../common/TransForm/Index";
import { PageTitle } from "../../common/PageTitle";
import Divider from '@mui/material/Divider';
import matchweekinput from '../../common/jsons/formConfigs/matchweekinput'
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import ModalPopUp from "../../common/TransForm/ModalPopUp";
import { base_url } from "../../backendurl/url";
import Axios from "axios";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";

export const MatchweekForm = ({ mode }) => {

    const [popup, setPopup] = useState(false)
    const [leagues, setLeaguesData] = useState([])
    const navigate = useNavigate();
    const [matchweek, setMatchweek] = useState({});
    const [errorName,setErrorName] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    
    const onSubmit = async (data) => {
        const errorFlag =  data.match_week_name.includes('-')?false:true
        setErrorMessage('The Match Week format is incorrect. It must have a "-". The correct format should be: MatchWeek-1.')
        setErrorName(errorFlag)
        if(!errorFlag ){
            setMatchweek(data)
            setPopup(true)
        }
        
    };


    const getTeamLeagues = async () => {
        try{
            const response = await Axios.get(`${base_url}/api/pot/configureleagues?status=true`, Config)
            let leaquename = response?.data?.data.map(item => {
                return {
                    id: item?.id,
                    name: item?.name,
                    ...item
                }
            })
            setLeaguesData(leaquename)
        }
       catch(error){
        RefreshToken(error,setErrorMessage,setErrorName) 
       }
    }

    useEffect(() => {
        getTeamLeagues();
    }, []);


    const SubmitTeams = async () => {
        try{
            delete matchweek.Add
            delete matchweek.match_week_id
    
            const response = await Axios.post(`${base_url}/api/pot/matchweek`, matchweek, Config)
            if (response.data.status == 'Success') {
                setPopup(false)
                navigate('/configurations/matchweeks')
            }
        }
       catch(error){
        RefreshToken(error,setErrorMessage,setErrorName) 
       }

    }

    return (
        <Box  >
            <Box sx={{ px: 6, py: 3 }} alignContent="center">
                <PageTitle pageTitle={'Configuring New Match Week'} />
                <Typography>Enter the data below to configure a new match week</Typography>
            </Box>
            <Divider sx={{ mb: 2, height: 4 }} />

            <TransForm
                submitAction={onSubmit}
                mode={mode}
                formInputs={[
                    ...matchweekinput({
                        leagues,
                    }),
                ]}
                
            />
            <ModalPopUp
                openpopup={popup}
                setPopup={setPopup}
                title={'Confirm the action ?'}
                subTitle={'Are you sure, you want to create a new match week with given details.'}
                onClick={SubmitTeams}
                save={'Yes, Create'}
                cancel={'Exit'}
            />
            <Notification message={errorMessage} show={errorName} setopen={setErrorName} />
        </Box>
    );
};
