import {
    Box,
    TextField,
    Typography,
    InputAdornment,
    InputLabel,
    FormHelperText,
    Grid,
    TextareaAutosize,
  } from "@mui/material";
  
  import lockicon from "../../../images/lockIcon.png";
  
  
  import _ from "lodash";
  import { useEffect } from "react";
  import { customAlphabet } from "nanoid";
  import CurrencyInput from "./CurrencyInput";
  import { BorderAllRounded } from "@mui/icons-material";
  const nanoid = customAlphabet("1234567890ABCDEFGHIJKLMNOPQRSTUZVWXYZ", 6);
  
  export const PnpTextArea = ({
    label,
    placeholder,
    value,
    onChange,
    inputAdornments,
    width,
    height,
    sx,
    customsx,
    wrapperSx,
    type,
    name,
    validation,
    errors,
    key,
    isDisabled,
    mode,
    autoGenerate,
  }) => {
  
    useEffect(() => {
      if (autoGenerate && !value && mode === "new") {
        onChange(name, nanoid());
      }
    }, [autoGenerate, mode, name, onChange, value]);
   
    const onTextChange = (e) => {

      return onChange(name, e.target.value);
    };
  
    return (
      <Box sx={{ ...wrapperSx }} key={key}>
      <InputLabel htmlFor={name} component="div" sx={{ fontWeight: 600, fontSize: 16, color: '#000',mb:1, error: errors?.status }}>
        {label}
        {validation?.required && <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>}
      </InputLabel>      
        
        <textarea disabled={isDisabled} onChange={onTextChange} value={value} rows={4} style = {{maxWidth : '100% !important',width :'100% ' ,fontFamily : 'Urbanist',fontSize : '16px', maxHeight : '600px',height : '100px'}} >

        </textarea>
 
        {/* <TextareaAutosize
     disabled={isDisabled || mode === "view"}
     placeholder={placeholder}
     value={value}
     onChange={onTextChange}
     error={errors?.status}
    
     style={{ backgroundColor : isDisabled ? '#E2E8F0' : 'intial',width: '100% !important', height :'100px', ...sx }}
     
    /> */}
        {/* <TextField
          type={type}
          variant="outlined"
          disabled={isDisabled || mode === "view"}
          placeholder={placeholder}
          value={value}
          onChange={onTextChange}
          error={errors?.status}
          sx={{ backgroundColor : isDisabled ? '#E2E8F0' : 'intial',width: width || "auto", height, ...sx }}
          // InputProps={{
          //   startAdornment: inputAdornments?.start && (
          //     <InputAdornment position="start" sx={{ color: "#111111" }}>
          //       <Typography color="#111111">{inputAdornments?.start}</Typography>
          //     </InputAdornment>
          //   ),
          // }}
          InputProps={{
            sx: customsx ? customsx : {borderRadius: '4px'},
            endAdornment: inputAdornments?.end && (
              <InputAdornment position="start" sx={{ color: "#111111" }}>
                {inputAdornments?.end}
              </InputAdornment>
            ),
            endAdornment: inputAdornments?.end && (
              <InputAdornment position="end" >
                <Typography color="#111111">{inputAdornments?.end}</Typography>
              </InputAdornment>
            ),
          }}
        /> */}
        
        {errors?.status && (
          <FormHelperText error={errors.status}>
            {_.capitalize(errors?.message)}
          </FormHelperText>
        )}
      </Box>
    );
  };
  
  
  