import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Button, Stack, Typography } from "@mui/material";
import Capsule from "../common/Capsule";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import UpdatePopup from "./UpdatePopup";
import { Dateformater } from "../common/TransForm/dateFormater";
import { localTimeWithZoneToUTCTime, utcTimetoLocalTimeWithzone } from "../common/TransForm/Localdateformater";
import AddFixture from "./AddFixture";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import Edit from '../../images/editIcon.svg'
import { Notification } from "../common/Notification";


export default function FixtureResults({ season_id, league, season }) {
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [addpopup, setAddpopup] = useState(false);
  const [matchdata, setMatchdata] = useState([]);
  const [notification, setnotification] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
  const [fixtureResult, setFixtureResult] = useState([]);
const [popupType,setpopupType] = useState('update_results')
  const updateResults = (data) => {
    setpopupType('update_results')
    setMatchdata(data);
    setPopup(true);
  };

  const userTableColumns = () => [
    {
      key: "match_week",
      label: "Match Week",
      align: "left",
      fontSize: "15px",

      valueRenderer: (data) => {
        return (
          <Typography sx={{ width: "180px" }}>{data["match_week"]}</Typography>
        );
      },
    },
    {
      key: "hometeam",
      label: "Home Team",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        return (
          <Typography sx={{ width: "180px" }}>{data["hometeam"]}</Typography>
        );
      },
    },
    {
      key: "awayteam",
      label: "Away Team",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        return (
          <Typography sx={{ width: "180px" }}>{data["awayteam"]}</Typography>
        );
      },
    },
    {
      key: "venue",
      label: "Venue",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        return <Typography sx={{ width: "180px" }}>{data["venue"]}</Typography>;
      },
    },
    {
      key: "start",
      label: "Match Date",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        let date = utcTimetoLocalTimeWithzone(data["start"]);
        const [datePart, timePart] = date.split(", ");

        return <Typography sx={{ width: "100px" }}> {datePart} </Typography>;
      },
    },

    {
      key: "Start",
      label: "Start Time",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        let date = utcTimetoLocalTimeWithzone(data["start"]);
        const [datePart, timePart] = date.split(", ");
        return <Typography sx={{ width: "100px" }}> {timePart}</Typography>;
      },
    },

    {
      key: "result",
      label: "Results",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        return (
          <Typography sx={{ width: "150px" }}>{data["status"]}</Typography>
        );
      },
    },

    {
      key: "action",
      label: "Action",
      align: "center",
      fontSize: "14px",
      px: "10px",

      valueRenderer: (data) => {
        const time = new Date()
        const matchDate = new Date(data.start)
        if (data.result == "None" && time>matchDate ) {
          return (
            <Capsule
              data="Update Results"
              onClick={() => updateResults(data)}
              bgColor="#F1F5F9"
              color="#3C50E0"
              sx={{
                borderRadius: "6px",
                width: "150px !important",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <Typography
              sx={{
                borderRadius: "6px",
                backgroundColor: "#fff",
                color: "#AAAAAA",
                width: "150px !important",
              }}
            >
              Update Results
            </Typography>
          );
        }
      },
    },

    {
      key: "action",
      label: "Action",
      align: "center",
      valueRenderer: (data) => {
        return (
          <Box>
            <Typography
              sx={{ color: '#3C50E0', fontSize: '14px', borderRadius: '6px' }}
              onClick={() => EditDateTime(data)}
            >
              {data.status == 'Launched' ? '' :
                <img src={Edit} alt='saved' style={{margin:'auto',cursor: "pointer"}} />}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   key: "action",
    //   label: "Action",
    //   align: "center",
    //   fontSize: "14px",
    //   px: "10px",

    //   valueRenderer: (data) => {
        
    //       return (
    //         <Capsule
    //           data="Edit"
    //           onClick={() => EditDateTime(data)}
    //           bgColor="#F1F5F9"
    //           color="#3C50E0"
    //           sx={{
    //             borderRadius: "6px",
    //             width: "150px !important",
    //             cursor: "pointer",
    //           }}
    //         />
    //       );
        
    //   },
    // }
  ];


  const EditDateTime = (data) => {
    const date = new Date(data.start);
    let formattedDate = new Date(date.toLocaleDateString('en-US'));
    formattedDate = `${formattedDate.getFullYear()}-${(formattedDate.getMonth() + 1).toString().padStart(2, '0')}-${formattedDate.getDate().toString().padStart(2, '0')}`
    const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    data.start_date = formattedDate
    data.start_time = formattedTime

    setpopupType('match_edit')
    setMatchdata(data);
    setPopup(true);
  }

  const addfixturejson = () => [
    {
      title: {
        label: "Update Results",
        type: "",
      },
      subTitle: {
        label: `${matchdata?.match_week} | ${matchdata?.hometeam} V ${
          matchdata?.awayteam
        } | ${Dateformater(matchdata?.start)}`,
        type: "",
      },
      matchStatus: {
        lable: "Match Status",
        type: "select",
        options: ["Inprogress", "Complete"],
      },
      hometeam_goal: {
        lable: matchdata.hometeam,
        type: "text",
      },
      awayteam_goal: {
        lable: matchdata.awayteam,
        type: "text",
      },
      matchResult: {
        lable: "Match Result",
        type: "select",
        options: [matchdata.hometeam, matchdata.awayteam],
      },
      save: {
        label: "update",
        type: "button",
      },
      cancel: {
        label: "cancel",
        type: "button",
      },
    },
  ];

  const getConfig = useCallback(async () => {
   
      try {
        if (!loading) {
          setLoading(true);
        const response = await Axios.get(
          `${base_url}/api/pot/fixtures?league=${season_id}&season=${season}`,
          Config
        );

        if (response?.status == 200) {
          setFixtureResult(response?.data?.data);
        }
        setLoading(false);
      }
      }
       catch(error) {
        RefreshToken(error,setErrorMessage,setnotification) 
      }
  
  }, []);

  const gotoAddUser = () => {
    // navigate("add");
    setAddpopup(true);
  };

  useEffect(() => {
    getConfig();
  }, []);

  const handleUpdate = async (data) => {
    try {
      if(popupType=='update_results'){
        matchdata.awayteam_goal = data?.awayteam_goal;
        matchdata.hometeam_goal = data?.hometeam_goal;
        matchdata.result = data?.matchResult;
        matchdata.status = data?.Status;
      }
     else{
      let start = `${data.start_date}T${data.start_time}:00Z`
      let date = new Date(start)
      let localStart = localTimeWithZoneToUTCTime(start)
      matchdata.start = localStart
      delete matchdata.start_date
      delete matchdata.start_time
      const end_time =new Date(date.setHours(date.getHours()+2))
      let localEnd = localTimeWithZoneToUTCTime(end_time.toISOString())
      matchdata.end = localEnd
      matchdata.status = data?.Status
     }

      const response = await Axios.put(
        `${base_url}/api/pot/fixtures/${matchdata?.id}`,
        matchdata,
        Config
      );
      if (response?.status == 200) {
        setPopup(false);
        getConfig();
      }
    } 
    catch(error) {
      RefreshToken(error,setErrorMessage,setnotification) 
    }
  };

  const handleAdd = async (data) => {
   

    try {
      delete data.time;
      const response = await Axios.post(
        `${base_url}/api/pot/fixtures`,
        data,
        Config
      );
      if (response?.status == 200) {
        getConfig();
      }
    } 
    catch(error) {
      RefreshToken(error,setErrorMessage,setnotification) 
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            onClick={gotoAddUser}
            sx={{
              width: "200px",
              mb: 3,
              mr: 2,
              backgroundColor: "#3C50E0",
              color: "#fff",
              px: 4,
              borderRadius: 1,
              border: "1px solid #3C50E0",
              "&:hover": {
                backgroundColor: "#3C50E0",
                border: "1px solid #3C50E0",
              },
            }}
          >
            {" "}
            Add Match  +{" "}
          </Button>
        </Box>
        <TransTable
          showSearch={true}
          checkbox={false}
          columns={userTableColumns()}
          rows={fixtureResult}
          filter_keys={[
            "match_week",
            "hometeam",
            "awayteam",
            "venue",
            "result",
          ]}
        />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
      {popup ? <>
        <UpdatePopup
        openpopup={popup}
        setPopup={setPopup}
        type ={popupType}
        title={"Update Results"}
        json={addfixturejson}
        data={matchdata}
        save={"update"}
        cancel={"Exit"}
        onClick={handleUpdate}
      />
      </> : ''}
    
      <AddFixture
        openpopup={addpopup}
        onClose={setAddpopup}
        onAddRow={handleAdd}
        state={{ league: league, season: season, season_id: season_id }}
      />
    </>
  );
}
