import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { rest } from "lodash";

export const TransButton = ({
  variant = "contained",
  title = "",
  onClick,
  sx,
  type,
  isDisabled,
  mode,
  name,
  ...rest
}) => {

  return (

    <Button
      onClick={()=>onClick(name)}
      variant={variant}

      disableElevation
      type={type}
      disabled={type === "action" && isDisabled}
      sx={{
        borderRadius: "3px",
        boxShadow: "none",
        fontSize: 16,
        ml : 2,
        fontWeight : 600,
        mb:6,
        textTransform: 'none !important',
        "&.Mui-disabled": {
          border: "none", // Remove border
        },
        ...sx
        
      }}
      style={{ textTransform: 'none' }}
      {...rest}
    >
      {title}
    </Button>
  );
};
