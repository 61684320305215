import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate} from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components

import MenuPopover from "../../components/common/MenuPopover";
// import { auth } from 'src/firebase/FirebaseInit';
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/action";
import dropdownBtn from '../../images/dropdownIcon.png'

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
    linkTo: "/",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "/dashboard/updateprofile",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    linkTo: "#",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover(props) {

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };


  const navigate = useNavigate();
  const { userInfo } = props;
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const username = getCookie('userName')
  const isadmin = getCookie('isadmin')
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const navigateTo = (link) => {
    navigate(link);
    handleClose();
  };

  const changePassword = () =>{
    const user_uuid = getCookie('user_uuid')
    navigate(`/users/changepassword/${user_uuid}`);
    handleClose();
  }
  const signout = async () => {
    handleClose();
    dispatch(loginAction({}));
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "isadmin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "userName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user_uuid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "is_superuser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "menuData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    localStorage.clear()
    navigate("/auth/login");
    window.location.reload();
  };

  return (
    <>
       

      <IconButton
        ref={anchorRef}
        sx={{
          p: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
          mr:'30px !important'
        }}
      >
        {/* <Avatar
          src={userInfo.profile_pic || "/static/avatars/avatar_default.jpg"}
          alt="photoURL"
        /> */}
         <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap  sx={{ textTransform: 'capitalize' }} >
            {username} 
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {isadmin ? 'Admin User' : 'Player'}
          </Typography>
        </Box>
        
        <img src={dropdownBtn} alt="dropdown" style={{ width: "15px",  height: "15px",}} onClick={handleOpen}/>
      </IconButton>

      <MenuPopover 
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mr:'30px !important'
        }}
      >
        <Box  sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userInfo.first_name} {userInfo.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userInfo.email}
          </Typography>
        </Box>

        {/* <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              onClick={() => navigateTo(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} /> */}
        <MenuItem onClick={changePassword} sx={{ m: 1 }}>
          Change Password
        </MenuItem>
        <MenuItem onClick={signout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
