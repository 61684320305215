import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  FormHelperText,
  Typography,
  InputLabel,
  Box,
  InputAdornment,
} from "@mui/material";
import _ from "lodash";

export default function TransDatePicker({
  label,
  placeholder,
  value,
  onChange,
  width,
  height,
  sx,
  wrapperSx,
  type,
  name,
  validation,
  errors,
  key,
  isDisabled,
  mode,
  inputAdornments
}) {
  const onDateChange = (value) => {
    return onChange(name, value);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} error={errors?.status}>
      <Box sx={{ ...wrapperSx }} key={key}>
      <InputLabel htmlFor={name} component="div" sx={{ fontWeight: 500, fontSize: 16, color: '#000', error: errors?.status }}>
        {label}
        {validation?.required && <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>}
      </InputLabel>
        <DatePicker
          disabled={isDisabled || mode === "view"}
          value={value == ''? null: value}
          inputFormat="DD/MM/YYYY"
          placeholder={placeholder}
          error={errors?.status}
          // label={placeholder ? placeholder : ""}
          onChange={(newValue) => {
            onDateChange(newValue);
          }}
          sx={{
            width:'100%'
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              error={errors?.status}
              sx={{ width, height, ...sx }}
            />
          )}
          inputProps={{ 
            type: "search",
            sx:{borderRadius: '4px'},
            endAdornment: inputAdornments?.end && (
              <InputAdornment position="start" sx={{ color: "#111111" }}>
                {inputAdornments?.end}
              </InputAdornment>
            ),
            endAdornment: inputAdornments?.end && (
              <InputAdornment position="end" >
                <Typography color="#111111">{inputAdornments?.end}</Typography>
              </InputAdornment>
            ),
          }}
        />
        {errors?.status && (
          <FormHelperText error={errors.status}>
            {_.capitalize(errors?.message)}
          </FormHelperText>
        )}
      </Box>
    </LocalizationProvider>
  );
}
