import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import {
  Box,
  IconButton,
  Switch,
  Typography,
  Tab,
  Stack,
  Button,
} from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../../service";
import {
  setActiveMatchWeek,
  setLoading,
  setSelectedTeam,
  setSelectedUser,
  setUsers,
} from "../../../redux/action";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Iconify from "../../common/Iconify";
import Capsule from "../../common/Capsule";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import palette from "../../../theme/palette";
import { Dateformater } from "../../common/TransForm/dateFormater";
import ModalPopUp from "../../common/TransForm/ModalPopUp";
import PotSetting from "../PotSetting";
import { TransButton } from "../../common/TransButton";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Excel from "../../../images/Excel.svg";
// import FixtureResults from "./FixtureResults";

function CompleteMatchWeek() {
  const {ActiveMatchWeek, PotSetting } = useSelector((state) => state);
  const { id } = useParams("id");
  const [loading, setLoading] = useState(false);
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const location = useLocation()
  const {matchweek,pot_id} = location.state;

  // File Extension and Type for excel download
  const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const userTableColumns = [
    { key: "player", label: "Players", align: "left", fontSize: "15px",},
    { key: "pick", label: "Picks No", align: "left", fontSize: "15px" },
    { key: "match", label: "Match Selected", align: "left", fontSize: "15px" },
    { key: "prediction", label: "Pick", align: "left", fontSize: "15px" },
    { key: "pick_result", label: "Player Status", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
        if (data.pick_result == "Win") {
          return <Capsule data='Win' bgColor='#21965333' color='#219653' />;
        } else if (data.pick_result == "Lost") {
          return <Capsule data='Lost' bgColor='#96592114' color='#965921' />;
        }else {
          return <Capsule data='Open' bgColor='#96592114' color='#965921' />;
        }
      },
    },
  ];


  const userExcelTableColumns = [
    { key: "pick", label: "Picks Id", align: "left", fontSize: "15px" },
    { key: "player", label: "Players", align: "left", fontSize: "15px",},
    { key: "player_email", label: "Email", align: "left", fontSize: "15px",},
    { key: "player_mobilenumber", label: "Mobile Number", align: "left", fontSize: "15px",},
    { key: "match", label: "Match Selected", align: "left", fontSize: "15px" },
    { key: "prediction", label: "Pick", align: "left", fontSize: "15px" },
    { key: "pick_result", label: "Player Status", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
        if (data.pick_result == "Win") {
          return 'Win';
        } else if(data.pick_result == "Lost"){
          return 'Lost';
        }
        else {
          return 'Open';
        }
      },
      },
  ];

  const getUsers = useCallback(async () => {
    setLoading(true);
    dispatch(setActiveMatchWeek([]));
    try{
      if (!loading) {
        const matchweek = await Axios.get(
          `${base_url}/api/pot/potmatchweekplayers?pot=${pot_id}&match_week=${id}`,
          Config
        );
   
        dispatch(setActiveMatchWeek(matchweek?.data?.data));
        setLoading(false)
     
      }
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
   }
  }, []);


  const downloadExcel = async (jsonData, fileName) => {
    
    const filteredData = jsonData.map((item) => {
      const newItem = {};
      userExcelTableColumns.forEach((col) => {
        if (col.valueRenderer) {
          newItem[col.label] = col.valueRenderer(item);
        } else {
          newItem[col.label] = item[col.key];
        }
      });
      return newItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={matchweek} />
        <Box sx={{ marginLeft: "10px", cursor: 'pointer' }}>
          <img width={'50px'} src={Excel} alt="excel" onClick={()=>downloadExcel(ActiveMatchWeek,"Completed_rounds_Matchweek")} />
        </Box>
      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round ID:</span> {PotSetting?.id}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Category:</span> {PotSetting?.category}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League:</span>  {PotSetting?.league}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Value:</span> {PotSetting?.pot_currency} {PotSetting?.current_pot_value}
        </Typography>
      </Box>
          <TransTable
            showSearch={false}
            checkbox={false}
            columns={userTableColumns}
            rows={ActiveMatchWeek}
            showAddBtn={false}
            isLoading={loading}
          />
          <Box sx = {{display : 'flex',px:6}}>
          </Box>
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />
    </Box>
    
  );
}

export default CompleteMatchWeek;
