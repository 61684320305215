import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setmatchweek } from "../../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import { useState } from "react";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";

export default function MatchList() {
  const navigate = useNavigate();
  const { matchweek} = useSelector((state) => state);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const userTableColumns = () => [
    {
      key: "match_week_id",
      label: "Match Week ID",
      align: "left",
      fontSize: '15px',

      valueRenderer: (data) => {
        return (
          <Link
            component={Typography}
            style={{ color: "black", textDecoration: "none" }}
          >
            {data["id"]}
          </Link>
        );
      },
    },
    { key: "match_week_name", label: "Match Week Name", align: "left", fontSize: "15px" },
    { key: "leaguename", label: "League Name", align: "left", fontSize: "15px" },
 
  ];

  const getUsers = useCallback(async () => {
    try{
      if ( !loading) {
        setLoading(true)
        const response = await Axios.get(`${base_url}/api/pot/matchweek`, Config);
        if (response?.status == 200) {
          dispatch(setmatchweek(response?.data?.data));
      }
        setLoading(false)
      }
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification) 
   }
  }, []);

  const gotoAddUser = () => {
    navigate("add");
    dispatch(setmatchweek({}));
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Box  >
        <TransTable
          pageTitle="Configuring Match Week"
          subTitle={'Teams from database are listed below'}
          addBtnTitle={'Configure a new match week +'}
          checkbox={false}
          columns={userTableColumns()}
          rows={matchweek}
          addBtnAction={gotoAddUser}
          showSearch={true}
          filter_keys ={['id','match_week_name','leaguename']}
          isLoading={loading}

        />
         <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />
      </Box>
    </>
  );
}
