import { InputAdornment } from "@mui/material";
import lockicon from "../../../../images/lockIcon.png";


export default function configNewTeam(values) {
    return [
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridColumnGap: "100px",
        },
        children: [
          {
            shape: "input",
            type: "text",
            name: "league_id",
            // handleChange: "",
            className: "border",
            title: "League Id",
            label: "League Id",
            placeholder: "ID",
            width: "100%",
            isDisabled : true,
            inputAdornments:{
                end: <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment>,
              }
            // validation: {
            //   required: true,
            // },
          },
          {
            shape: "input",
            type: "text",
            name: "league_name",
            // handleChange: "",
            className: "border",
            title: "League",
            label: "League",
            placeholder: "English Premier League",
            width: "100%",
            validation: {
              required: true,
            },
          },
          {
            shape: "input",
            type: "select",
            name: "is_active",
            // handleChange: "",
            className: "border",
            title: "Status",
            label: "Status",
            options: [{name:'Active',id:true}, {name:'Inactive',id:false}],
            placeholder: "Status",
            width: "100%",
            validation: {
              required: true,
            },
          }

        ],
      },
     
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
        children: [
            {
                shape: "button",
                type: "action",
                title: values.mode === "view" ? "Edit" : "Add",
                submitButton : true,
                name: "Add",
                sx : {mr:2,
                  },
                validation: {
                  hiddenRules: [
                    {
                      mode: "view",
                    },
                  ],
                },
              },
          {
            shape: "button",
            type: "cancel",
            title: "Cancel",
            variant: "outlined",
            name: "cancel",
            sx: {
              mr: 2,
             
            },
          },
         
        ],
      },
    
    ];
  }
  