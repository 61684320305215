
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';




export default function MultiSelectFilter({options,onChange,filterData,filterKey}) {
    const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 150,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      onChange(value)
    

    };
    return (
        <div>
          <FormControl sx={{ }}>
            {/* <InputLabel id="demo-multiple-checkbox-label">League</InputLabel> */}
            <Select
              labelId="demo-multiple-checkbox-label"
            //   id="demo-multiple-checkbox"
              multiple
              value={filterData[filterKey].length? filterData[filterKey]  : [{name:'League',value:''}]}
              onChange={handleChange}
              input={<OutlinedInput  />}
              sx={{ '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                outline:'none'
              },width:'140px',
              '& .MuiSelect-select': {
                padding: '3px 3px', 
                border:'none',
                margin:0,
                backgroundColor:'#F0F0F0',
              },
            }}
              renderValue={(selected) =>{ 
               let result = selected.map(item => item.name).join(',').replace('League,','')
               return result}}
              MenuProps={MenuProps}
            >
              {options.map((name) => (
                <MenuItem key={name.value} value={name}>
                  <Checkbox checked={filterData[filterKey].indexOf(name) > -1} />
                  <ListItemText primary={name.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
}




