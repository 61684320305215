import React, { useState ,useEffect} from "react";
import { Box } from "@mui/system";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
  Tooltip,
  Text,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { Typography } from "@mui/material";
import DateRangeFilter from "./DateRangeFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import Excel from '../../images/Excel.svg'
import axios from "axios";
import { Config } from "../../utils/commonFuctions/Token";
import { currencyformater } from "../common/TransForm/currencyformater";
import { useSelector } from "react-redux";
function CustomLabel(props) {
  const { x, y, value } = props;

  return (
    <Text
      x={x}
      y={y}
      dx={2}
      dy={-1}
      fontSize={15}
      fontWeight="bold"
      fill="#3C50E0"
      textAnchor="center"
    >
      {value}
    </Text>
  );
}

function Barchart({ type,data,title,filters=null,filterData,setfilterData,getUrl ,getFunction ,align,filterkey,options,downloadUrl}) {
  const {navOpen} = useSelector((state) => state);
  const NameValue = {
  in_active : {
    name : 'time_period',
    value:'value',
    xlabel : 'Date',
    ylabel : 'Non-Active  Players'
  },
  top_players : {
    name : 'player__username',
    value:'total_spent',
    xlabel : 'Player name',
    ylabel : 'Purchased Tokens'
  },
  league : {
    name : 'display_name',
    value:'number_of_players',
    xlabel : 'League Name',
    ylabel : 'Playing Players'
  },
  location:{
    name : 'country',
    value:'total_users',
    ylabel : 'Registered Players',
    xlabel : 'Country'
  }
}

const [verticalPoints,setverticalPoints] = useState(null)
// 488
const point =480/data?.length
let tempPoint = 65
const verticalPointsData = data? data.map((item,index)=>{
  return tempPoint + (point * index)
}) :[]
verticalPointsData.push(550)





useEffect(()=>{
},[navOpen])


const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

function formatExcelDate(date) {   
  const yy = String(date.getFullYear()).slice(-2);  
   const mm = String(date.getMonth() + 1).padStart(2, '0');   
   const dd = String(date.getDate()).padStart(2, '0');   
   const hh = String(date.getHours()).padStart(2, '0');  
    const min = String(date.getMinutes()).padStart(2, '0');   
    const ss = String(date.getSeconds()).padStart(2, '0');
     return yy + mm + dd + hh + min + ss; }


const downloadExcel = async() =>{

      downloadUrl = downloadUrl + `&end=${formatDate(filterData[filterkey][0]?.endDate)}&start=${formatDate(filterData[filterkey][0]?.startDate)}`
      const excelResponse = await axios.get(
        downloadUrl,
        Config
      );
      const file = new Blob([excelResponse?.data], { type: 'text/csv' });
  // process to auto download it
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = title +'_'+formatExcelDate(new Date()) + ".csv";
  link.click();
      
}

// const CustomLabel = (props) => {
//   const { x, y, value } = props;
//   const formattedValue = new Intl.NumberFormat().format(value); // Format the value here
//   return (
//     <text x={x} y={y} dy={-6} style={{textAlign:'center'}} fill="#8884d8" fontSize={12} textAnchor="middle">
//       {formattedValue}
//     </text>
//   );
// };

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{backgroundColor:'#fff',padding:4,border: '1px solid gray'}}>
        <p className="label">{`${label}`}</p>
       {payload.map(item=>{
        return <p style={{color:item.color}}><span >{item.name}</span> : <span>AED {currencyformater(item.value)}</span></p>
       })}
      </div>
    );
  }
 
  return null;
};

  return (
    <Box sx={{ width: "45vw", height: 430,backgroundColor:'#fff',paddingLeft:3 }}>

      <Box sx={{display:'flex',padding:3,justifyContent:'space-between'}} >

      <Typography sx={{fontSize:'18px',fontWeight:600}}> {title} </Typography>

      <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',textAlign:'center'}}>
      {filters ? filters=='date'?
      <DateRangeFilter
      onChange={item => {
        filterData[filterkey] = item
        setfilterData(filterData)
        getFunction(getUrl,item)
      }}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={filterData}
      title={title}
      direction="horizontal"
      filterJSON={{'apiKey':filterkey}}
      align={align}
    /> : <MultiSelectFilter 
    options = {options?options:[]}
    onChange = {item => {
      filterData[filterkey] = item
      setfilterData(filterData)
      getFunction(getUrl,item)
    }}
    filterData = {filterData}
    filterKey = {filterkey}
    /> : ''
      }
      <Box sx={{padding:'6px',marginLeft:'10px',background:'#F0F0F0',cursor:'pointer'}}>
        <img src={Excel} alt='excel' onClick={downloadExcel}/>
      </Box>
      </Box>

      
      </Box>

      {data?.length?
     <ResponsiveContainer width="94%" height={300}>
 <BarChart className="bar_chart" width={850} height={300} data={data}>
            <CartesianGrid stroke="#ccc"             // Color of the grid lines
          strokeDasharray="0 0"     // Pattern of the grid lines (dashed lines)
          vertical={true}           // Show vertical grid lines
          horizontal={true}  
          verticalPoints={verticalPointsData} 
            />
            <XAxis
              tick={{ fontSize: 8 }}
              dataKey={NameValue[type].name ? NameValue[type].name :'name'}
              axisLine={false}
              angle={data?.length <8 ? 0 :-90} 
              interval={0}
              textAnchor="end"
              height={60} 
              label={{ value: NameValue[type].xlabel, position: 'insideCenter' ,dx:-10,dy:26 }}
              // ticks={data.XAxis}
            />
            {filterkey == 'top_players' ?  <Tooltip content={<CustomTooltip />}/> : <Tooltip/>}
                     
            <YAxis 
            axisLine={false}
            label={{ value: NameValue[type].ylabel, angle: -90, position: 'insideCenter' ,dx:-30 }}
            />
            <Bar dataKey={NameValue[type].value ? NameValue[type]?.value :'value'} fill="#3C50E0" />
            {/* <LabelList dataKey={NameValue[type].value ? NameValue[type].value :'value'} position="top" content={<CustomLabel />} />
              </Bar> */}
          </BarChart> 
     </ResponsiveContainer>
                   
      : <Typography sx={{height:"280px",display:'flex',justifyContent:'center',alignItems:'center'}}>No Data Found</Typography>}

    </Box>
  );
}

export default Barchart;
