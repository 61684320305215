
import { useNavigate,useLocation } from "react-router-dom";
import { setLoading } from "../../redux/action";
import _ from "lodash";

import AuthWrapper from "./AuthWrapper";
import {
  Box,
  Button,
  Stack,

} from "@mui/material";
import { Form, Formik } from "formik";
import { TextField, Grid } from "@mui/material";
import { useState } from "react";
import { useStyles } from "./PlaceHolderStyle";
import Axios  from "axios";
import { base_url } from "../backendurl/url";



export default function ForgotPassword({ mode }) {
  const classes = useStyles();
  const {state} = useLocation()
  const navigate = useNavigate();

  const [formdata, setFormData] = useState({
    newpassword: "",
    confirmpassword: ""
  })

  const updatePasswordForUser = async () => {
    formdata['email'] = state.email
    formdata['token'] = state.token
    setLoading(true);
    const response = await Axios.put(
      `${base_url}/api/accounts/changepassword`,
      formdata
    );
    setLoading(false);

        navigate("/", { replace: true });
  };

  const validate = () => {
    const errors = {};
 if(formdata.newpassword != '' && formdata.confirmpassword != '' )
 {
  if (!/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,12}$/.test(formdata.newpassword )) {
    errors.newpassword = 'Password must be 8 to 12 characters, at least one uppercase letter, one number, and one special character'
  }
  else if (formdata.newpassword != formdata.confirmpassword) {
    errors.confirmpassword = 'Password does not match'
  }
 }
 if (formdata.newpassword == '') {
  errors.newpassword = 'Required Password';
} 

if (formdata.confirmpassword == '') {
  errors.confirmpassword = 'Required Confirm Password'
}
return errors;
 }
   

  const handleChange = (e, setErrors) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name == 'newpassword') {
      if (value.length >= 6) {
        setErrors({ [name]: '' })
      }
    } else {
      setErrors({ [name]: '' })
    }
 
  }

 
  return (
    <AuthWrapper
      title={
        mode === "update_password"
          ? "Update your password"
          : "Reset your password"
      }
      subtitle="Please enter a strong password with
      atleast six characters"
    >
      <Box sx={{ mt: 0 }}>

          <Formik
          initialValues={formdata}
          validate={validate}
          onSubmit={updatePasswordForUser}
        >
          {({ errors, touched, setErrors }) => (
            <Form >
              <Box sx={{ mt: 3, borderRadius: 19,width: '25vw' }}>
                <Stack direction="column" justifyContent="center">
                  <TextField
                    sx={{ mb: 2}}
                  
                    
                    name="newpassword"
                    placeholder="New Password*"
                    type='password'
                    className ={classes.root}
                    onChange={(e) => handleChange(e, setErrors)}
                    error={touched.newpassword && errors.newpassword}
                    helperText={touched.newpassword && errors.newpassword}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "12px",
                        backgroundColor:' #0000001F',
                        border: "none",
                        outline: "none",
                      },
                    }}
                  />
                  <TextField
                    sx={{ mb: 2}}
                    name="confirmpassword"
                    placeholder=" Confirm New Password *"
                    type="password"
                    className ={ classes.root}
                    onChange={(e) => handleChange(e, setErrors)}
                    error={touched.confirmpassword && errors.confirmpassword}
                    helperText={touched.confirmpassword && errors.confirmpassword}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "12px",
                        backgroundColor:' #0000001F',
                        border: "none",
                        outline: "none",
                        color : ' #00000099'
                      },
                    }}
                  />
                </Stack>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#0081F8" }}
                  sx={{ width: "350px", height: "40px", mt: 4, boxShadow: 'none' }}
                >
                  Reset Password
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </AuthWrapper>
  );
}
