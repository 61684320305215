export default function Button(theme) {
  return {
    select: {
      styleOverrides: {
        outlinedInherit: {
          backgroundColor: "#fff",
          borderRadius: 0,
          color: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlined: {
          backgroundColor: "#fff",
          borderRadius: 0,
          color: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
