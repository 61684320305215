import { storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { async } from "@firebase/util";

export const FirebaseImageUpload = async (file) => {


  try {
    let time = Date.now()
    const storageRef = ref(storage, `images/${file?.name}-${time}`);
    const uploadTask = await uploadBytesResumable(storageRef, file);
    const url = await getDownloadURL(uploadTask.ref);
    return url;
  } catch (error) {
    console.error("Error getting download URL:", error);
    return error;
  }
};


