// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            // textFillColor: "black",
            "& svg": { color: theme.palette.text.disabled },
          },
          borderRadius: "0px",
          height: "45px",
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#D1D1D1",
          },
          border: "1px solid #D3D3D3",
          borderRadius: "0px",
          height: "45px",
          padding: 0,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          "&:hover": {
            backgroundColor: theme.palette.grey[500_16],
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.action.focus,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "45px",
          borderRadius: "0px",
          input: {
            border: "none",
            padding: "0",
            paddingLeft: 15,
            color: "#000",
            fontWeight: 500,
            "&::placeholder": {
              opacity: 1,
              color: "#D1D1D1",
            },
            "&.Mui-disabled": {
              borderColor: theme.palette.common.white,
              color: theme.palette.common.black,
              // textFillColor: "black",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid",
            borderColor: "#D3D3D3",
          },
          "&.Mui-disabled": {
            borderColor: theme.palette.common.white,
            color: theme.palette.common.black,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
  };
}
