import { Box, Divider, Typography } from "@mui/material";
import { TransForm } from "../common/TransForm/Index";
import { PageTitle } from "../common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { setPotSettingList, setSelectedLeagues, setPotSetting } from "../../redux/action";
import { useCallback, useEffect } from "react";
import axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Notification } from "../common/Notification";
import NewPotCreate from "../common/jsons/formConfigs/NewPotCreate";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";

export const WaitlistForm = ({ mode, title }) => {
  const { PotSetting, selectedLeagues, PotSettingList } = useSelector(
    (state) => state
  );
  const [popup, setPopup] = useState(false);
  const [notification, setnotification] = useState(false);
  const [errorNotifcation,setErrornotification] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_uuid } = useParams("user_uuid");
  const [loading, setLoading] = useState(false);
  const [potConfig, setPotConfig] = useState({});
  const [isupdate, setIsupdate] = useState(false);
  const [configData, setConfigdata] = useState();
  const [error, seterror] = useState("");
  const [allFieldDisable, setFieldDisable] = useState(false);
  const [onChangeValue, setonChangeValue] = useState();
  const [potCategoryList, setPotCategoryList] = useState();
  const [errorMessage, setErrorMessage] = useState('')
  const [saveBtnText, setSaveBtnText] = useState({
    title: "Do you want to save new pot?",
    content:
      "Are you sure, you want to create & save a new pot with given details.",
    btnText: "yes , Save",
  });

  const SubmitConfig = async () => {
    try{
      delete configData.prediction_deadline
      delete configData.adminfee
      await axios.post(`${base_url}/api/pot/pots`, configData, Config);
      navigate("/rounds");
      setPopup(false);
      getPotConfig();
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setErrornotification)
   }
  };


  const onSubmit = async (data, name) => {
    setSaveBtnText({
      title: "Do you want to save new pot category ?",
      content:
        "Are you sure, you want to create & save a new pot category with given details.",
      btnText: "yes , Save",
    });
    data.max_pot_value = typeof data.max_pot_value === "string" ? parseFloat(data.max_pot_value.replace(/,/g, "")) : data.max_pot_value;
    data.min_pot_value = typeof data.min_pot_value === "string" ? parseFloat(data.min_pot_value.replace(/,/g, "")) : data.min_pot_value;
    data.value_of_token = typeof data.value_of_token === "string" ? parseFloat(data.value_of_token.replace(/,/g, "")) : data.value_of_token;
    data.deadline_predictions = parseInt(data.prediction_deadline);
    data.admin_fee = parseInt(data.adminfee);
    data.max_active_picks_voting	 = parseInt(data.min_picks_for_splitting);
    data.status = "Waitlist";
    data.current_pot_value = 0;
    data.token_value = data.value_of_token
    data.payment_status = false;
    data.last_entry_time = new Date();
    data.max_number_of_picks = data.picks_per_player;
    data.season = data.season;
    data.category = data.pot_category;
    data.deadline_voting= parseInt(data.deadline_voting);
    delete data.Submit;
    data.pot_config = PotSetting.id;
    if (data.max_pot_value < data.min_pot_value) {

      setnotification(true);
    } else {
      setPopup(true);
      setnotification(false);
      setConfigdata(data);
    }
  };

  const getPotConfig = useCallback(async () => {
    seterror("");
    setLoading(true);
    setLoading(false);
  }, [PotSetting, user_uuid]);

  //fill data
  const FillPotCategory = async (id) => {
    let potSetting = PotSettingList.find((item) => {
      return item.id == id;
    });
    if (potSetting) {
      potSetting.category = id;
      potSetting.league = onChangeValue;
      delete potSetting.created_at
      delete potSetting.created_by
      delete potSetting.updated_by
      delete potSetting.updated_at
      dispatch(setPotSetting(potSetting));
    }
  };


  //get league details
  const getPotCategory = async (id) => {
    try{
      setonChangeValue(id);
      const response = await axios.get(
        `${base_url}/api/pot/potconfigs?season=${id}`,
        Config
      );
  
  
      //fill select dropdown
      const categoryList = response?.data?.data.map((item) => {
        return {
          id: item?.pot_category,
          name: item?.pot_category,
          ...item,
        };
      });
  
      let fileterdata = categoryList?.filter(item => item.status == "Launched")
  
      dispatch(setPotSettingList(fileterdata));
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setErrornotification)
   }
  };


  const getConfig = async () => {
    try{
      const response = await axios.get(`${base_url}/api/pot/seasondropdown`, Config);
      dispatch(setPotSetting({}));
  
      const leaquename = response?.data?.data.map((item) => {
        return {
          id: item?.id,
          name: item?.display_name,
          ...item,
        };
      });
  
      dispatch(setSelectedLeagues(leaquename));
      setPotCategoryList(leaquename);
    }
  catch(error){
    RefreshToken(error,setErrorMessage,setErrornotification)
  }
  };


  useEffect(() => {
    getConfig();
  }, []);



  return (
    <Box>
      <Box sx={{ px: 6, pt: 3 }} alignContent="center">
        <PageTitle pageTitle={"Create New Round"} />
      </Box>
      <Divider sx={{ mb: 2, height: 4 }} />
      <TransForm
        submitAction={onSubmit}
        data={PotSetting}
        mode={mode}
        cardlength={"threeCard"}
        formInputs={NewPotCreate({
          mode,
          selectedLeagues,
          getPotCategory,
          FillPotCategory,
          PotSettingList,
          tokendisable: isupdate,
          allFieldDisable,
        })}
      />
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={saveBtnText.title}
        subTitle={saveBtnText.content}
        onClick={SubmitConfig}
        save={saveBtnText.btnText}
        cancel={"Exit"}
      />
      <Notification
        message={"Min Pot Value Should not be Higher than Max Pot Value"}
        setopen={setnotification}
        type={"error"}
        show={notification}
      />
 <Notification
        message={errorMessage}
        setopen={setErrornotification}
        type={"error"}
        show={errorNotifcation}
      />
      
    </Box>
  );
};
