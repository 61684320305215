import { InputAdornment } from "@mui/material";
import lockicon from "../../../../images/lockIcon.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { options } from "numeral";

export default function UserFormInputs(values) {
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  return [
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "50px",
      },
      children: [
        {
          shape: "input",
          type: "text",
          name: "id",
          // handleChange: "",
          className: "border",
          title: "Admin Id",
          label: "Admin Id",
          placeholder: "ID",
          isDisabled : true,
          inputAdornments:{
              end: <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment>,
            },
          width: "100%",
          // validation: {
          //   required: true,
          // },
        },
        {
          shape: "input",
          type: "text",
          name: "username",
          // handleChange: "",
          className: "border",
          title: "Admin Name",
          label: "Admin Name",
          placeholder: "Enter your name",
          width: "100%",
          validation: {
            required: true,
          },
        },

      ],
    },
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "50px",
        mt: 6,
      },
      children: [
        {
          shape: "input",
          type: "email",
          name: "email",
          // handleChange: "",
          className: "border",
          title: "Email",
          label: "Email",
          placeholder: "Enter your email",
          width: "100%",
          validation: {
            required: true,
          },
        },
        {
          shape: "input",
          type: "number",
          name: "mobile_number",
          // handleChange: "",
          className: "border",
          title: "Mobile",
          label: "Mobile",
          placeholder: "Enter your mobile",
          width: "100%",
          validation: {
            required: true,
            minLength:10,
          },
        },

      ],
    },
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "50px",
        mt: 6,
      },
        children: [
        {
          shape: "input",
          type: values?.mode == 'new' ? "text" : "select",
          name: "is_active",
          // handleChange: "",
          className: "border",
          title: "Status",
          label: "Status",
          options: values?.mode == 'new' ? "" : [{name:'Active',id:true}, {name:'Inactive',id:false}],
          isDisabled : values?.mode == 'new' ? true : false,
          inputAdornments:{
              end: values?.mode == 'new' ? <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment> :'',
            },
          placeholder: values?.mode == 'new' ? "New user" : "User",
          width: "100%",
          
          // validation: {
          //   required: true,
          // },
        },
        {
        //  type: values.eyeOpen? "text" : "password",
          type:'password',
          name: "password",
          // handleChange: "",
          title: "password",
          label: "Password",
          className: "",
          placeholder: "Password",
          width:"100%",
          validation: {
            required:  values?.mode == 'new' ? true : false,
            minLength:8,
            requiredMessage: "Password can't be empty",
          },
          inputAdornments:{
            end:  <InputAdornment 
            sx={{cursor: 'pointer'}} position="start">
          {values.eyeOpen ? <FaEye/> : <FaEyeSlash/>}
          </InputAdornment>,
          },
          show: values?.mode == 'new' ? true : false
        },
      ],
    }, 

    getCookie('is_superuser') === 'true' ?
    {
      type: "menuselect",
      label: "Access Menu",
      name: "menu_permissions",
      placeholder:"Select Menu",
      options:[
        {
          label:"Dashboard",
          value:"dashboard"
        },
        {
          label:"Leagues",
          value:"leagueseason"
        },
        {
          label:"Rounds",
          value:"pots"
        },
        {
          label:"Players",
          value:"user"
        },
        {
          label:"Users",
          value:"user"
        },
        {
          label:"Payouts",
          value:"payout"
        },
        {
          label:"Purchases",
          value:"paymentsreceipts"
        },
        {
          label:"Configurations",
          value:"configurations",
          children:[
            {
              label:"Round Parameters",
              value:"potconfig"
            },
            {
              label:"Leagues",
              value:"configuredleagues"
            },
            {
              label:"Teams",
              value:"teams"
            },
            {
              label:"Match Weeks",
              value:"leaguematchweek"
            },
          ]
        },
        {
          label:"Customer Query",
          value:"tickets"
        },
      ]
    } : '',
    {
      shape: "div",
      type: "wrapper",
      className: "",
      sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
      children: [
        {
          shape: "button",
          type: "action",
          title: values.mode === "view" ? "Edit" :values.mode == 'new' ? 'Add' : "Save Changes",
          // submitButton : values.mode === "view" ? false : true ,
          name: "Add",
          validation: {
            hiddenRules: [
              {
                mode: "view",
              },
            ],
          },
        },
        {
          shape: "button",
          type: "cancel",
          title: "Cancel",
          variant: "outlined",
          name: "cancel",
          sx: {
            mr: 2,
          },
        },
      ],
    },
    
    // {
    //     type: "number",
    //     shape: "input",
    //     label: "Phone Number",
    //     name: "mobile",
    //     className: "border",
    //     title:"mobile",
    //     validation: {
    //         required: true,
    //         minLength: 10,
    //         maxLength:10
    //     }
    // },
  ];
}
