import { Box, Button, Grid } from "@mui/material";
import { TransForm } from "../../common/TransForm/Index";
import leagueFormSystem from "../../common/jsons/formConfigs/leagueFormSystem";
import leagueFormManual from "../../common/jsons/formConfigs/leagueFormManual";
import { PageTitle } from "../../common/PageTitle";
import Divider from '@mui/material/Divider';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import ModalPopUp from "../../common/TransForm/ModalPopUp";
import { base_url } from "../../backendurl/url";
import Axios from "axios";
import { Config } from "../../../utils/commonFuctions/Token";
import { Notification } from "../../common/Notification";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";

export const LeagueForm = ({ mode }) => {

  const [popup, setPopup] = useState(false)
  const [leagues, setLeaguesData] = useState([])
  const { selectedleagues } = useSelector(
    (state) => state
  );
  const [ismanual, setIsmanual] = useState('')
  const navigate = useNavigate();
  const [leaguedata, setLeagues] = useState({});
  const [shownotification, setShownotification] =useState(false)
 
  const [errorMessage, setErrorMessage] = useState('')


  const onSubmit = async (data) => {
    if (ismanual == 'system') {
      const filterdata = leagues?.find(item => item.id === data.league_name);
      await Promise.resolve(); 
      setLeagues({
        league_id: filterdata?.system_league_id ? filterdata?.system_league_id : '',
        name: filterdata?.name,
        active: data?.is_active
      });
    } else {
      setLeagues({
        name: data?.league_name,
        active: data?.is_active
      })
    }
    setPopup(true)
  };


  const getTeamLeagues = async () => {
    setIsmanual('')
    try{
      const response = await Axios.get(`${base_url}/api/pot/leagues`, Config)
      let leaquename = response?.data?.data.map((item) => {
        return {
          ...item,
          id: item?.league_id,
          name: `${item?.league_name} - ${item?.country}` ,
        }
      })
      setLeaguesData(leaquename)
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setShownotification) 
    }
    }
    

  useEffect(() => {
    getTeamLeagues();
  }, []);

  const SubmitTeams = async () => {
    try {
      const response = await Axios.post(`${base_url}/api/pot/configureleagues`, leaguedata, Config)
      if (response?.data.status === 'Success') {
        setPopup(false)
        navigate('/configurations/leagues')
      }
    } catch (error) {

       if(error.response.status === 422) {
        setErrorMessage('League is Already Exist')
        setShownotification(true)
        setPopup(false)
      }else{
        RefreshToken(error,setErrorMessage,setShownotification) 
      }
     
    }


  }

  return (
    <Box  >
      <Box sx={{ px: 6, py: 3 }} alignContent="center">
        <PageTitle pageTitle={ismanual == 'manual' ? 'Configuring New League Manually' : 'Configuring New League'} />
        <Typography>Enter the data below to configure a new league</Typography>
      </Box>
      <Divider sx={{ mb: 2, height: 4 }} />
      {
        ismanual == '' ?
          <Box sx={{ px: 6, py: 3 }} alignContent="center">
            <Button onClick={() => setIsmanual('system')} sx={{ mr: 2, backgroundColor: '#3C50E0', color: '#fff', px: 4, borderRadius: 1, border: '1px solid #3C50E0', '&:hover': { backgroundColor: '#3C50E0', border: '1px solid #3C50E0' } }}> Fetch Leagues </Button>
            <Button onClick={() => setIsmanual('manual')} sx={{ mr: 2, backgroundColor: '#FFF', color: '#000', px: 4, borderRadius: 1, border: '1px solid #E2E8F0', '&:hover': { backgroundColor: '#FFF', border: '1px solid #E2E8F0' } }}> Manually Add Leagues </Button>
          </Box> :
          ismanual == 'system' ?
            <TransForm
              submitAction={onSubmit}
              data={selectedleagues}
              mode={mode}
              //passFormData={setFormData}
              formInputs={[
                ...leagueFormSystem({
                  leagues
                }),
              ]}
            />
            :
            <TransForm
              submitAction={onSubmit}
              data={selectedleagues}
              mode={mode}
              formInputs={leagueFormManual({ mode })}
            />
      }
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Confirm the action ?'}
        subTitle={'Are you sure, you want to create a new league with given details.'}
        onClick={SubmitTeams}
        save={'Yes, Create'}
        cancel={'Exit'}
      />
      <Notification message={errorMessage} setopen={setShownotification} type={'error'} show={shownotification} />
    </Box>
  );
};
