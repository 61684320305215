import { Box } from "@mui/material";
import { TransForm } from "../common/TransForm/Index";
import passWordChangeFormInputs from "../common/jsons/formConfigs/changePasswordConfig"
import { PageTitle } from "../common/PageTitle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { base_url } from '../backendurl/url'
import { useState } from "react";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export const ChangePassword = ({ mode }) => {

    const [popup, setPopup] = useState(false)
    const navigate = useNavigate();
    const [notification, setnotification] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [passwordData, setPasswordData] = useState({});
    const [saveBtnText, setSaveBtnText] = useState({
        title: 'Change Password ?',
        content: 'Are you sure, you want to save the changes made on Admin',
        btnText: 'yes , Save'
    })

    const onSubmit = (data) => {
        setPasswordData({
            old_password: data.old_password,
            new_password: data.new_password
        })
        setPopup(true)
    }

    const SubmitPassword = async () => {
        try {
            if (passwordData) {
                const response = await axios.put(`${base_url}/api/accounts/adminchangepassword`, passwordData, Config);
                if (response?.data?.status === 'Success') {
                    navigate(`/users`);
                }
                else {
                    setnotification(true);
                    setErrorMessage('Your old password was wrong')
                    setPopup(false)
                }
            }
        }
        catch (error) {
            RefreshToken(error, setErrorMessage, setnotification)
        }
    }


    return (
        <Box>
            <Box display="flex" sx={{ px: 6, pt: 3 }} alignContent="center">
                <PageTitle pageTitle={'Change Password'} backBtn />
            </Box>

            <TransForm
                submitAction={onSubmit}
                data={passwordData}
                mode={mode}
                formInputs={passWordChangeFormInputs({ mode })}
            />

            <ModalPopUp
                openpopup={popup}
                setPopup={setPopup}
                title={saveBtnText.title}
                subTitle={saveBtnText.content}
                onClick={SubmitPassword}
                save={saveBtnText.btnText}
                cancel={'Exit'}
            />
            <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

        </Box>
    )
}
