import { InputAdornment } from "@mui/material";
import lockicon from "../../../../images/lockIcon.png";


export default function configNewTeam(values) {
    return [
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
        },
        children: [
          {
            shape: "input",
            type: "text",
            name: "teamid",
            // handleChange: "",
            className: "border",
            title: "Team Id",
            label: "Team Id",
            placeholder: "ID",
            width: "100%",
            isDisabled : true,
            inputAdornments:{
                end: <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment>,
              }
            // validation: {
            //   required: true,
            // },
          },
          {
            shape: "input",
            type: "select",
            name: "league",
            // handleChange: "",
            className: "border",
            title: "League",
            label: "League",
            options: values.leagues,
            placeholder: "English Premier League",
            width: "100%",
            validation: {
              required: true,
              
            },
          },
         

        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
          mt: 6,
        },
        children: [
          {
            shape: "input",
            type: "text",
            name: "name",
            // handleChange: "",
            className: "border",
            title: "Team Name",
            label: "Team Name",
            placeholder: "Enter team name",
            width: "100%",
            validation: {
              required: true,
            },
          },
            {
                shape: "input",
                type: "text",
                name: "groundname",
                // handleChange: "",
                className: "border",
                title: "Home Venue",
                label: "Home Venue",
                placeholder: "Enter home venue",
                width: "100%",
                
                validation: {
                  required: true,
                },
              },    
         
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
          mt: 6,
        },
        children: [
          {
            shape: "input",
            type: "text",
            name: "code",
            // handleChange: "",
            className: "border",
            title: "Team Name",
            label: "Short Name",
            placeholder: "Enter team short name",
            width: "100%",
            validation: {
              required: true,
              maxLength:10,
            },
          },
          {
            shape: "input",
            type: "file",
            name: "logo",
            uploadType: 'inlineDocument',
            mode:'view',
            handleChange: "",
            className: "border",
            title: "logo",
            label: "Logo",
            placeholder: "Elogo",
            width: "100% !important",
            fileRestriction : {height:150,width:150},
            accept:'image/*',
            validation: {
              required: true,
            },
          },  
         
        ],
      },
  
    
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
        children: [
            {
                shape: "button",
                type: "action",
                title: values.mode === "view" ? "Edit" : "Add",
                submitButton : true,
                name: "Add",
                sx : {mr:2,
                  backgroundColor : '#3C50E0',
                  px:4,
                borderRadius: 1,
                border : '1px solid #3C50E0',
                '&:hover': {
                  backgroundColor: '#3C50E0',
                  border : '1px solid #3C50E0' // Remove background color change on hover
                },},
                validation: {
                  hiddenRules: [
                    {
                      mode: "view",
                    },
                  ],
                },
              },
          {
            shape: "button",
            type: "cancel",
            title: "Cancel",
            // variant: "outlined",
            name: "cancel",
            sx: {
              mr: 2,
              color: '#000',
              px: 4,
              borderRadius: 1,
              backgroundColor : '#fff',
              border : '1px solid #E2E8F0',
              '&:hover': {
                backgroundColor: '#fff',
                border : '1px solid #E2E8F0' // Remove background color change on hover
              },
            },
          },
         
        ],
      },
      // {
  
      //     shape:"select",
      //     type: "select",
      //     label: "Role",
      //     name: "role",
      //     className:"border",
      //     validation: {
      //         required:true
      //     },
      //     options: [
      //         {
      //             label: "Admin",
      //             value: "admin"
      //         },
      //         {
      //             label: "User",
      //             value: "user"
      //         }
      //     ]
      // },
      // {
      //     type: "number",
      //     shape: "input",
      //     label: "Phone Number",
      //     name: "mobile",
      //     className: "border",
      //     title:"mobile",
      //     validation: {
      //         required: true,
      //         minLength: 10,
      //         maxLength:10
      //     }
      // },
    ];
  }
  