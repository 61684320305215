
import React, { useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, CartesianGrid } from 'recharts';
import { Box, Typography } from "@mui/material";
import DateRangeFilter from "./DateRangeFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import axios from "axios";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Config } from "../../utils/commonFuctions/Token";
import { options } from "numeral";
import Excel from '../../images/Excel.svg'
import { currencyformater } from "../common/TransForm/currencyformater";




const CustomLineChart = ({ xAxisLabel, yAxisLabel,filterData,setfilterData,options, data = [],getUrl,setData,align,title ,filters,filterkey,getFunction,downloadUrl}) => {
  if (!data) {
    // Handle the case where data is not an array
    return null; // Or display a message, or some other fallback behavior
  }
 let dataSet = {
    Cashflow : {
            commisions: {
              name: "Admin Fee",
              color: "#80CAEE",
              value:'commisions'
              // data: cashFlow?.data?.data?.commisions,
            },
            payouts: {
              name: "Total Payouts",
              color: "#0FADCF",
              value:'payouts'
            },
            contributions: {
              name: "Total Contributions",
              color: "#3C50E0",
              value:'contributions'
            },
          }
  }
  // const formatDate = (date) => {
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  //   const day = String(d.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };
  // const getCashFlow = async (value) => {
  //   try {
  //     let url = getUrl + `?start=${formatDate(value[0].endDate)}&end=${formatDate(value[0].startDate)}`
  //     const cashFlow = await axios.get(
  //       url,
  //     Config
  //     );
  //     if (cashFlow.status == 200) {
  //       setData(cashFlow?.data?.data);
  //     }
  //   } catch (error) {
  //     RefreshToken(error);
  //   }
  // };


  const formatYAxisLabel = (value) => { 
      return `${currencyformater( value)}`;
  };

  const formatXAxisLabel = (value) => {
 
    const formattedDate = new Date(value).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' }).replace(/\//g, '/');
    
    return formattedDate;
  
};

const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const downloadExcel = async() =>{
  if(typeof downloadUrl === 'object'){
   Object.keys(downloadUrl).map(key=>{
   
    ExcelDataDownload(key,downloadUrl[key])
   })
  }

  // if(filterData[filterkey]){downloadUrl = downloadUrl + `&start=${formatDate(filterData[filterkey][0].endDate)}&end=${formatDate(filterData[filterkey][0].startDate)}`}
  //     const excelResponse = await axios.get(
  //       downloadUrl,
  //       Config
  //     );
  //     const file = new Blob([excelResponse?.data], { type: 'text/csv' });
  // // process to auto download it
  // const fileURL = URL.createObjectURL(file);
  // const link = document.createElement('a');
  // link.href = fileURL;
  // link.download = 'PNP excel' + ".csv";
  // link.click();
  // console.log(excelResponse?.data,'klll')
      
}

const ExcelDataDownload = async(key,url) => {
url = url + `&end=${formatDate(filterData[filterkey][0].endDate)}&start=${formatDate(filterData[filterkey][0].startDate)}`
      const excelResponse = await axios.get(
        url,
        Config
      );
      const file = new Blob([excelResponse?.data], { type: 'text/csv' });
  // process to auto download it
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  // link.download = key + ".csv";
  link.download = key +'_'+formatExcelDate(new Date()) + ".csv";

  link.click();
}

function formatExcelDate(date) {   
  const yy = String(date.getFullYear()).slice(-2);  
   const mm = String(date.getMonth() + 1).padStart(2, '0');   
   const dd = String(date.getDate()).padStart(2, '0');   
   const hh = String(date.getHours()).padStart(2, '0');  
    const min = String(date.getMinutes()).padStart(2, '0');   
    const ss = String(date.getSeconds()).padStart(2, '0');
     return yy + mm + dd + hh + min + ss; }

const CustomActiveDot = (props) => {   const { cx, cy, stroke, fill } = props;   return (     
  <circle cx={cx} cy={cy} r={2} stroke={stroke}strokeWidth={2} fill={stroke} /> ); };


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'#fff',padding:4,border: '1px solid gray'}}>
          <p className="label">{`${label}`}</p>
         {payload.map(item=>{
          return <p><span style={{color:item.color}}>{item.name}</span> : <span>AED {currencyformater(item.value)}</span></p>
         })}
        </div>
      );
    }
   
    return null;
  };

  return (
    <Box sx={{height:430,width:'32vw',backgroundColor:'#fff',position:'relative'}}>
           <Box sx={{display:'flex',padding:4,justifyContent:'space-between'}} >
      <Typography sx={{fontSize:'18px',fontWeight:600}}> {title} </Typography>
      <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',textAlign:'center'}}>
      {filters ? filters=='date'?
      <DateRangeFilter
      onChange={item => {
        filterData[filterkey] = item
        setfilterData(filterData)
        getFunction(getUrl,item)
      }}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={filterData}
      align={align}
      filterJSON={{"apiKey":filterkey}}
      direction="horizontal"
    /> : <MultiSelectFilter
    options = {options?options:[]}
    onChange = {item => {
      filterData[filterkey] = item
      setfilterData(filterData)
      getFunction(getUrl,item)
    }}
    filterData = {filterData}
    filterKey = {filterkey}
    /> :
    ''
      }
       {/* <Box sx={{padding:'6px',marginLeft:'10px',background:'#F0F0F0',cursor:'pointer'}}>
        <img src={Excel} alt='excel' onClick={downloadExcel}/>
      </Box> */}
      </Box>

      </Box> 
      <Box  sx={{height:'250px',width:'30vw',position:'absolute'}} >
      <ResponsiveContainer>
        <LineChart data={data} height={'200px'}>
        <CartesianGrid stroke="#eee" strokeDasharray="0 0" />
          <XAxis dataKey="date" type="category" label={{ value: xAxisLabel, position: 'insideCenter' ,dx:-20,dy:14 }} axisLine={false} tickFormatter={formatXAxisLabel}/>
          <YAxis  label={{ value:yAxisLabel, angle: -90, position: 'insideLeft' }} axisLine={false} />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          {Object.values(dataSet[filterkey]).map((series, index) => {
            return <Line key={index} type="monotone" dataKey={series.value} name={series.name} stroke={series.color} dot={<CustomActiveDot />} />
          })}
          {/* {Object.keys(dataSet).map((seriesKey, index) => {
            const series =data[seriesKey]
            return <Line key={index} type="monotone" dataKey='value' data={series.data} name={series.name} stroke={series.color} dot={<CustomActiveDot />} />
          })} */}
          </LineChart>

      </ResponsiveContainer>
      <Box sx={{display:'flex',flexWrap:'wrap',width:'100%',marginLeft:2,justifyContent:'center',marginTop:3}}>
          {Object.values(dataSet[filterkey]).map(item=>{
           
            return <Box sx={{display:'flex',padding:1,alignItems:'center',fontSize:'10px',marginRight:0}}>
              <Box sx={{backgroundColor:item.color,width:'15px',height:'15px',marginRight:1}}></Box>
            <Typography sx={{fontSize:'12px'}}>{item.name}</Typography>
            </Box>
           
          })}
        </Box>
      </Box>
     
    </Box>
  );
};

export default CustomLineChart;
